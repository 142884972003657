const getKeyValue = (device, key, unit, roundValue) => {
    if (device.properties.find(p => p.key === key)) {
        const value = roundValue
            ? Math.round(parseFloat(device.properties.find(p => p.key === key).value)).toString()
            : device.properties.find(p => p.key === key).value;
        const isNotNumeric = isNaN(value);
        if (!isNotNumeric) {
            if (value === undefined || value === null || value === "") return "-";
            const result = parseFloat(value).toLocaleString();
            return unit ? result.replace(".", ",") + " " + unit : result.replace(".", ",");
        }
        return unit ? value + " " + unit : value;
    }
    return "-";
};
export default getKeyValue;

