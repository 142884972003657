import React, { useState } from 'react';
import { format, add, sub, startOfMonth } from 'date-fns';
import queryString from 'query-string';

import { Card, Typography, Space, Checkbox, Button, Alert, Table, Collapse } from 'antd';
import DatePicker from '../components/date-picker/DatePicker';

const apiUrl = "https://stunssolar.azurewebsites.net/api/parameter-value-differences";
const dateFormat = 'yyyy-MM-dd';

const ParameterValueDifferences = () => {
    const initialTimeInterval = {
        from: sub(startOfMonth(new Date()), { months: 1 }),
        to: sub(startOfMonth(new Date()), { days: 1 })
    };;

    const [ timeInterval, setTimeInterval ] = useState(initialTimeInterval);
    const [ normalize, setNormalize ] = useState(false);
    const [ isDownloading, setIsDownloading ] = useState(false);
    const [ errorMessage, setErrorMessage ] = useState(undefined);
    const [ data, setData ] = useState(undefined);

    const columns = [
        { title: 'Anläggning', dataIndex: ['device', 'identity'] },
        { title: 'Startvärde', dataIndex: 'startValue', align: 'right', render: o => <>{formatValue(o)}</> },
        { title: 'Slutvärde', dataIndex: 'endValue', align: 'right', render: o => <>{formatValue(o)}</> },
        { title: 'Differens', dataIndex: 'valueDifference', align: 'right', render: o => <>{formatValue(o)}</> }
    ];

    const isDisabled = () => {
        return !timeInterval;
    };

    const handleTimeIntervalChange = args => {
        setTimeInterval(args ? { from: args[0], to: args[1] } : undefined);
    };

    const handleNormalizeChange = e => {
        setNormalize(e.target.checked);
    };

    const handleSubmit = () => {
        setIsDownloading(true);
        setErrorMessage(undefined);
        setData(undefined);

        const headers = {};
        headers["Access-Control-Allow-Origin"] = "*";

        const parameters = {
            parameterName: "totalYield",
            from: format(timeInterval.from, "yyyy-MM-dd"),
            to: format(add(timeInterval.to, { days: 1 }), "yyyy-MM-dd"),
            normalize
        };

        const url = apiUrl + "?" + queryString.stringify(parameters);

        fetch(url, headers)
            .then(response => response.json())
            .then(json => {
                setIsDownloading(false);
                if(json) {
                    setData(json);
                } else {
                    setErrorMessage("Felaktig förfrågan. Du skrev troligen in ett felaktigt tidsspann.");
                }
            });
    };

    const formatValue = value => value.toFixed(3).replace('.', ',');

    return (
        <Card>
            <Typography.Title level={3}>
                Insamlad energi per anläggning för datumintervall
            </Typography.Title>
            <Space size="small" style={{ marginTop: '2em' }}>
                <DatePicker.RangePicker
                    defaultValue={[initialTimeInterval.from, initialTimeInterval.to]}
                    dateFormat={dateFormat}
                    disabled={isDownloading}
                    disabledDate={date => date > new Date()}
                    onChange={handleTimeIntervalChange}
                />

                <Checkbox onChange={handleNormalizeChange} disabled={isDownloading}>
                    Normera&nbsp;värden
                </Checkbox>

                <Button
                    type="primary"
                    loading={isDownloading}
                    onClick={handleSubmit}
                    disabled={isDisabled()}
                >
                    Hämta
                </Button>
            </Space>

            {
                errorMessage && <Alert type="error" message={errorMessage} />
            }
            {
                data &&
                (
                    <>
                        <Table
                            size="middle"
                            columns={columns}
                            dataSource={data}
                            pagination={false}
                            style={{ marginTop: '2em' }}
                        />
                        <Collapse style={{ marginTop: '2em' }}>
                            <Collapse.Panel header="JSON">
                                <pre>{JSON.stringify(data, undefined, 2)}</pre>
                            </Collapse.Panel>
                        </Collapse>
                    </>
                )
            }            
        </Card>
    );
};

export default ParameterValueDifferences;