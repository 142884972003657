import React from "react";
import strings from "../strings/strings";
import getKeyValue from "../helpers/getKeyValue";
import getUniqueKeyValuesFromSubDevices from "../helpers/getUniqueKeyValuesFromSubDevices.js";
import SpecificationTable from "./SpecificationTable/SpecificationTable";

function CompareDeviceSpecification(props) {
    const stringsPart = strings.comparePage.compareStepCompare.specificationPanel;

    const createDevices = (devices) => {
        return devices.map((device) => ({
            device: device,
            name: getKeyValue(device, "display name"),
            image: getKeyValue(device, "image urls").split(";")[0],
            onClick: "showModal",
        }));
    };

    const createDeviceSpec = (devices) => {
        if (devices.length < 1) return [];
        return [
            {
                key: "Produktion",
                value: [
                    {
                        key: stringsPart.lastweekTotalYieldProduction,
                        value: devices.map((device) =>
                            getKeyValue(device, "total yield during last week (kWh)", undefined, true)
                        ),
                    },
                    {
                        key: stringsPart.lastmonthTotalYieldProduction,
                        value: devices.map((device) =>
                            getKeyValue(device, "total yield during last month (kWh)", undefined, true)
                        ),
                    },
                    {
                        key: stringsPart.lastyearTotalYieldProduction,
                        value: devices.map((device) =>
                            getKeyValue(device, "total yield during last year (kWh)", undefined, true)
                        ),
                    },
                    {
                        key: stringsPart.totalYieldProduction,
                        value: devices.map((device) => getKeyValue(device, "total yield (kWh)", undefined, true)),
                    },
                    {
                        key: stringsPart.investment,
                        value: devices.map((device) => getKeyValue(device, "investment [sek/kwp]")),
                    },
                    {
                        key: stringsPart.installer,
                        value: devices.map((device) => getKeyValue(device, "installer")),
                    },
                ],
            },
            {
                key: "Anläggning",
                value: [
                    {
                        key: "Monteringssystem",
                        value: devices.map((device) => {
                            return getUniqueKeyValuesFromSubDevices(device.subDevices, "mounting system");
                        }),
                    },
                    {
                        key: "Monteringsvinkel [°]",
                        value: devices.map((device) => {
                            return getUniqueKeyValuesFromSubDevices(device.subDevices, "mounting angle") + "°";
                        }),
                    },
                    {
                        key: "Takvinkel [°]",
                        value: devices.map((device) => {
                            let angle = getKeyValue(device, "roof angle");
                            if (typeof angle === typeof []) {
                                angle = angle.map((v) => v + "°").join(", ");
                            } else {
                                angle += "°";
                            }
                            return angle;
                        }),
                    },
                    {
                        key: "Taktyp",
                        value: devices.map((device) => {
                            let roof_type = getKeyValue(device, "roof type");
                            if (typeof roof_type === typeof []) roof_type = roof_type.join(", ");
                            return roof_type;
                        }),
                    },
                    {
                        key: "Azimut [°]",
                        value: devices.map((device) => {
                            return getUniqueKeyValuesFromSubDevices(device.subDevices, "azimuth") + "°";
                        }),
                    },

                    {
                        key: "Modultyp",
                        value: devices.map((device) =>
                            getUniqueKeyValuesFromSubDevices(device.subDevices, "solar module type")
                        ),
                    },
                    {
                        key: "Verkningsgrad [%]",
                        value: devices.map((device) => {
                            let val = getUniqueKeyValuesFromSubDevices(device.subDevices, "efficiency [%]");
                            val = val.split(",");
                            val = val.map((v) => (parseFloat(v.trim()) * 100).toFixed(1).toString() + "%");
                            return val.join(", ");
                        }),
                    },
                    {
                        key: "Mer information",
                        ids: devices.map((device) => device.deviceId),
                        value: devices.map(() => "Visa modal"),
                    },
                ],
            },
            {
                key: "Modellbeskrivning",
                value: [
                    {
                        key: stringsPart.technology,
                        value: devices.map((device) =>
                            getUniqueKeyValuesFromSubDevices(
                                device.subDevices,
                                "solar cell type",
                                "solar cell technology"
                            )
                        ),
                    },
                    {
                        key: stringsPart.effect,
                        value: devices.map((device) => getKeyValue(device, "installed peak power [kwp]")),
                    },
                    {
                        key: stringsPart.solarPanelBrand,
                        value: devices.map((device) => getKeyValue(device, "solar panel brand")),
                    },
                    {
                        key: stringsPart.inverterBrand,
                        value: devices.map((device) => getKeyValue(device, "inverter brand")),
                    },
                    {
                        key: stringsPart.solarPanelModel,
                        value: devices.map((device) =>
                            getUniqueKeyValuesFromSubDevices(device.subDevices, "solar panel model")
                        ),
                    },
                ],
            },
        ];
    };

    return (
        <div style={{ overflowX: "auto" }}>
            <div style={{ width: "100%" }}>
                <SpecificationTable
                    empty="Inga valda anläggningar..."
                    allData={props.devices}
                    data={createDeviceSpec(props.devices)}
                    typeData={createDevices(props.devices)}
                    dataType="obj"
                    haveModal={true}
                />
            </div>
        </div>
    );
}
export default CompareDeviceSpecification;
