import React from "react";
import { Button, Col, Row } from "antd";

function FacilityCard(props) {
    const stringCheck = (value) => {
        if (value === undefined || value === null || value === "") return "-";
        return value;
    };

    return (
        <Col span={8} className="start-column">
            <div
                style={{
                    height: "100%",
                    paddingBottom: "1rem",
                }}
            >
                <div
                    onClick={() => props.onClick(props.index)}
                    className="bg-white p-1 wordWrap panel-style"
                    style={{
                        height: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                    }}
                >
                    <div>
                        <Row justify="center">
                            {props.image === "-" ? (
                                <span className="about-facility-card-img"> Bild kommer snart </span>
                            ) : (
                                <img
                                    className="about-facility-card-img"
                                    src={props.image?.split(";")[0]}
                                    alt="Facility"
                                />
                            )}
                        </Row>
                        <span className="h4 black-color"> {stringCheck(props.city)} </span>
                        <h1
                            className="h2 black-color"
                            style={{
                                margin: "0",
                            }}
                        >
                            {stringCheck(props.name)}
                        </h1>
                        <h3
                            style={{
                                margin: "0",
                            }}
                        >
                            {"Celltyp" + (props.cellType.split(",").length > 1 ? "er: " : ": ") + props.cellType}
                        </h3>
                        <h3
                            style={{
                                margin: "0",
                            }}
                        >
                            {"Taktyp" + (props.roofType.split(",").length > 1 ? "er: " : ": ") + props.roofType}
                        </h3>
                    </div>
                    <Button
                        className="button-secondary"
                        shape="round"
                        style={{
                            width: "70%",
                            margin: "1rem auto 0 auto",
                            display: "block",
                        }}
                    >
                        Visa detaljer
                    </Button>
                </div>
            </div>
        </Col>
    );
}

export default FacilityCard;
