import React, { useState } from "react";
import { Col, Checkbox } from "antd";

import "./CheckboxButton.scss";

function CheckboxButton(props) {
    const [checked, setChecked] = useState(props.checked);
    const borderColorSelected = props.borderColor === undefined ? "#007FFF" : props.borderColor;
    const borderColor = props.borderColor === undefined ? "#DDDDDD" : props.borderColor;

    return (
        <div
            className="width-100 checkboxButton-container"
            style={{
                border: `1px solid ${checked ? borderColorSelected : borderColor}`,
            }}
            onClick={(e) => {
                e.preventDefault();
                setChecked(!checked);
                props.onChange(props.value, !checked, props.valueKey);
            }}
        >
            <div className="checkboxButton-items">
                <Col className="checkbox-btn-label wordWrap">
                    <span style={{ color: "#000000" }}>{props.text}</span>
                </Col>
                <Col className="checkbox-btn-box">
                    <Checkbox
                        defaultChecked={props.checked}
                        checked={props.static ? props.checked : checked}
                    ></Checkbox>
                </Col>
            </div>
        </div>
    );
}
export default CheckboxButton;
