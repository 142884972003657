import React, { useState, useRef } from "react";
import { createParameterDefinitions } from "./logic.js";
import IsTabletOrMobile from "../IsTabletOrMobile.js";
import PropTypes from "prop-types";
import strings from "../../strings/strings";

import "./momentaneous-values.scss";

const MomentaneousValues = ({ data, renderCount }) => {
    const previousRef = useRef(undefined);

    const [parameterDefinitions] = useState(createParameterDefinitions());
    const isMobile = IsTabletOrMobile().mobile;

    const parametersToShow = [
        {
            key: "Sol produktion",
            value: [["power", "irradiation"], ["predictedPower"]],
        },
        {
            key: "Väder",
            value: [
                ["windSpeed", "windDirection"],
                ["relativeHumidity", "temperature"],
            ],
        },
    ];

    if (!data) return <></>;
    return (
        <div
            className="p-1 start-page-card"
            style={{
                backgroundColor: "white",
            }}
        >
            <h2 className="h2 black-color">{strings.startPage.currentProduction}</h2>
            <div
                className="mom-parameter-box-container"
                style={{
                    color: "black",
                    width: "100%",
                }}
            >
                {parametersToShow.map((section, i) => {
                    return (
                        <React.Fragment key={i}>
                            <div
                                style={{
                                    width: isMobile ? "100%" : "45%",
                                }}
                            >
                                <h2 style={{ textAlign: "center", display: "block", width: "100%" }}>{section.key}</h2>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                        width: "100%",
                                        justifyContent: "space-evenly",
                                    }}
                                >
                                    {section.value.map((val) => {
                                        return (
                                            <div
                                                style={{
                                                    width: "100%",
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "space-evenly",
                                                    flexDirection: isMobile ? "column" : "row",
                                                }}
                                                key={val}
                                            >
                                                {val.map((parameter, index) => {
                                                    const parameterDefinition = parameterDefinitions[parameter];
                                                    const parameterValue = parameterDefinition.getValue
                                                        ? parameterDefinition.getValue(data)
                                                        : data[parameter];
                                                    const title_defined =
                                                        parameterDefinition.title === "" ||
                                                        parameterDefinition.title === undefined;
                                                    const title = title_defined ? "-" : parameterDefinition.title;
                                                    return (
                                                        <div
                                                            key={title + index}
                                                            className="parameter-box"
                                                            style={{
                                                                width: "max-content",
                                                                height: "max-content",
                                                            }}
                                                            title={parameterDefinition.description}
                                                        >
                                                            <span
                                                                style={{
                                                                    width: "100%",
                                                                    display: "block",
                                                                    textAlign: "center",
                                                                    marginBlock: "1em",
                                                                }}
                                                            >
                                                                {title}
                                                            </span>
                                                            <img
                                                                src={parameterDefinition.icon}
                                                                alt={parameterDefinition.description}
                                                                className="icon"
                                                                style={
                                                                    parameterDefinition.getIconStyle
                                                                        ? parameterDefinition.getIconStyle(
                                                                              parameterValue,
                                                                              previousRef,
                                                                              renderCount
                                                                          )
                                                                        : undefined
                                                                }
                                                            />
                                                            <div className="value">
                                                                {parameterDefinition.formatValue(parameterValue)}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {parametersToShow.length - 1 > i && (
                                <div
                                    style={{
                                        display: "flex",
                                        flexWrap: "nowrap",
                                        flexFlow: isMobile ? "row" : "column",
                                        justifyContent: "space-evenly",
                                        height: isMobile ? "1px" : "auto",
                                        width: isMobile ? "100%" : "1px",
                                        margin: isMobile ? "1em 0" : "",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "black",
                                            width: isMobile ? "70%" : "1px",
                                            height: isMobile ? "1px" : "100px",
                                            display: i % 2 !== 0 && !isMobile ? "none" : "block",
                                        }}
                                    ></div>
                                    {!isMobile && (
                                        <div
                                            style={{
                                                backgroundColor: "black",
                                                width: isMobile ? "70%" : "1px",
                                                height: isMobile ? "1px" : "100px",
                                                display: i % 2 !== 0 && !isMobile ? "none" : "block",
                                            }}
                                        ></div>
                                    )}
                                </div>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

MomentaneousValues.propTypes = {
    data: PropTypes.object,
    renderCount: PropTypes.number
};

export default MomentaneousValues;
