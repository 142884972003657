const createPanelArray = (devices) => {
    const devicesWithSubDevices = devices.filter((d) => d.subDevices.length > 0);
    let subDevices = [];
    for (const device of devicesWithSubDevices) {
        subDevices.push(device.subDevices);
    }
    const flattenSubDevices = subDevices.flat();
    let sunPanels = [];
    let i = 1;
    for (const sub of flattenSubDevices) {
        if (sub.properties.find((s) => s.key === "solar panel model")) {
            const model = sub.properties.find((s) => s.key === "solar panel model").value;
            if (model !== null || (model !== undefined) !== "") {
                if (sunPanels.find((r) => r.properties.find((p) => p.value === model)) === undefined) {
                    sunPanels.push({
                        id: i,
                        properties: [
                            { key: "model", value: model },
                            {
                                key: "brand",
                                value: sub.properties.find((s) => s.key === "solar panel brand")
                                    ? sub.properties.find((s) => s.key === "solar panel brand").value
                                    : "-",
                            },
                            {
                                key: "power",
                                value: sub.properties.find((s) => s.key === "solar panel power [w]")
                                    ? sub.properties.find((s) => s.key === "solar panel power [w]").value
                                    : "-",
                            },
                            {
                                key: "height",
                                value: sub.properties.find((s) => s.key === "height [mm]")
                                    ? sub.properties.find((s) => s.key === "height [mm]").value
                                    : "-",
                            },
                            {
                                key: "width",
                                value: sub.properties.find((s) => s.key === "width [mm]")
                                    ? sub.properties.find((s) => s.key === "width [mm]").value
                                    : "-",
                            },
                            {
                                key: "thickness",
                                value: sub.properties.find((s) => s.key === "thickness [mm]")
                                    ? sub.properties.find((s) => s.key === "thickness [mm]").value
                                    : "-",
                            },
                            {
                                key: "cellType",
                                value: sub.properties.find((s) => s.key === "solar cell type")
                                    ? sub.properties.find((s) => s.key === "solar cell type").value
                                    : "-",
                            },
                            {
                                key: "technology",
                                value: sub.properties.find((s) => s.key === "solar cell technology")
                                    ? sub.properties.find((s) => s.key === "solar cell technology").value
                                    : "-",
                            },
                            {
                                key: "busbars",
                                value: sub.properties.find((s) => s.key === "busbars")
                                    ? sub.properties.find((s) => s.key === "busbars").value
                                    : "-",
                            },
                            {
                                key: "moduleType",
                                value: sub.properties.find((s) => s.key === "solar module type")
                                    ? sub.properties.find((s) => s.key === "solar module type").value
                                    : "-",
                            },
                            {
                                key: "efficiency",
                                value: sub.properties.find((s) => s.key === "efficiency [%]")
                                    ? sub.properties.find((s) => s.key === "efficiency [%]").value
                                    : "-",
                            },
                        ],
                        image: `/panels/${model}.jpg`,
                        smallImage: `/panels/small/${model}.jpg`,
                        displayName: model,
                    });
                    i++;
                }
            }
        }
    }
    const sortedPanels = sunPanels.sort((a, b) =>
        a.properties.find((p) => p.key === "model").value > b.properties.find((p) => p.key === "model").value
            ? 1
            : b.properties.find((p) => p.key === "model").value > a.properties.find((p) => p.key === "model").value
            ? -1
            : 0
    );
    return sortedPanels;
};
export default createPanelArray;
