const getUniqueKeyValuesFromSubDevices = (subDevices, firstKey, secondKey) => {
    let allKeyValuesFromSubDevices = [];
    for (const subDevice of subDevices) {
        if (secondKey) {
            if (subDevice.properties.find((p) => p.key === firstKey) && subDevice.properties.find((p) => p.key === secondKey)) {
                const firstValue = subDevice.properties.find((p) => p.key === firstKey).value;
                const secondValue = subDevice.properties.find((p) => p.key === secondKey).value;
                if (firstValue !== "" || firstValue !== undefined || firstValue !== null
                    || secondValue !== "" || secondValue !== undefined || secondValue !== null) {
                        const value = firstValue.concat(" ").concat(secondValue);
                        allKeyValuesFromSubDevices.push(value);
                }
            }
            if (subDevice.subDevices) {
                for (const subSubDevice of subDevice.subDevices) {
                    if (subSubDevice.properties.find((p) => p.key === firstKey) && subSubDevice.properties.find((p) => p.key === secondKey)) {
                        const firstValue = subSubDevice.properties.find((p) => p.key === firstKey).value;
                        const secondValue = subSubDevice.properties.find((p) => p.key === secondKey).value;
                        if (firstValue !== "" || firstValue !== undefined || firstValue !== null
                            || secondValue !== "" || secondValue !== undefined || secondValue !== null) {
                                const value = firstValue.concat(" ").concat(secondValue);
                                allKeyValuesFromSubDevices.push(value);
                        }
                    }
                }
            }
        } else {
            if (subDevice.properties.find((p) => p.key === firstKey)) {
                const value = subDevice.properties.find((p) => p.key === firstKey).value;
                if (value !== "" || value !== undefined || value !== null) {
                    allKeyValuesFromSubDevices.push(value);
                }
            }
            if (subDevice.subDevices) {
                for (const subSubDevice of subDevice.subDevices) {
                    if (subSubDevice.properties.find((p) => p.key === firstKey)) {
                        const value = subSubDevice.properties.find((p) => p.key === firstKey).value;
                        if (value !== "" || value !== undefined || value !== null) {
                            allKeyValuesFromSubDevices.push(value);
                        }
                    }
                }
            }
        }
    }
    const uniqueValues = allKeyValuesFromSubDevices.filter((elem, index, self) => {
        return index === self.indexOf(elem);
    })
    let uniqueValuesString = "";
    let i = 0;
    for (const uniqueValue of uniqueValues) {
        if ((uniqueValues.length - 1) === i) {
            uniqueValuesString = uniqueValuesString.concat(uniqueValue);
        } else {
            uniqueValuesString = uniqueValuesString.concat(uniqueValue + ", ");
        }
        i++;
    }
    return uniqueValuesString.length > 0 ? uniqueValuesString : ["-"];
};
export default getUniqueKeyValuesFromSubDevices;