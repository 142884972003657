import solarEnergyIcon from "../../assets/icons/solar-energy.svg";
import treeIcon from "../../assets/icons/tree.svg";

export const createParameterDefinitions = () => {
    return {
        totalYield: {
            title: "Solelproduktion",
            description: "Total mängd producerad solenergi",
            icon: solarEnergyIcon,
            formatValue: (value) => formatNumber(value, 0) + " kWh",
        },
        last365DaysYield: {
            title: "Solelproduktion",
            description: "Mängd producerad solenergi på ett år",
            icon: solarEnergyIcon,
            formatValue: (value) => formatNumber(value, 0) + " kWh",
        },
        last30DaysYield: {
            title: "Solelproduktion",
            description: "Mängd producerad solenergi på en månad",
            icon: solarEnergyIcon,
            formatValue: (value) => formatNumber(value, 0) + " kWh",
        },
        dayYield: {
            title: "Solelproduktion",
            description: "Mängd producerad solenergi på en dag",
            icon: solarEnergyIcon,
            formatValue: (value) => formatNumber(value, 0) + " kWh",
        },
        totalNumberOfTrees: {
            title: "Träd",
            description: "Toatal minskade koldioxidutsläpp omräknat till träd",
            icon: treeIcon,
            getValue: (data) => (0.1 / 14) * data.totalYield,
            formatValue: (value) => formatNumber(value, 0) + " träd",
        },
        yearNumberOfTrees: {
            title: "Träd",
            description: "Minskade koldioxidutsläpp omräknat till träd per år",
            icon: treeIcon,
            getValue: (data) => (0.1 / 14) * data.last365DaysYield,
            formatValue: (value) => formatNumber(value, 0) + " träd",
        },
        monthNumberOfTrees: {
            title: "Träd",
            description: "Minskade koldioxidutsläpp omräknat till träd per månad",
            icon: treeIcon,
            getValue: (data) => (0.1 / 14) * data.last30DaysYield,
            formatValue: (value) => formatNumber(value, 0) + " träd",
        },
        dayNumberOfTrees: {
            title: "Träd",
            description: "Minskade koldioxidutsläpp omräknat till träd per dag",
            icon: treeIcon,
            getValue: (data) => (0.1 / 14) * data.dayYield,
            formatValue: (value) => formatNumber(value, 0) + " träd",
        },
    };
};

const formatNumber = (number, decimals) =>
    isNaN(number)
        ? ""
        : number.toLocaleString("sv-SE", { minimumFractionDigits: decimals, maximumFractionDigits: decimals });

//totalElectricCarDistance: {
//    description: "Motsvarande elbilsmil all solenergi räcker till",
//    icon: electricCarIcon,
//    getValue: function (data) { return data.totalYield / 1.7; },
//    formatValue: function (value) { return formatNumber(value, 0) + " mil"; }
//},

// Vi räknar med att ett genomsnittligt svenskt träd binder in 14 kg CO2 per år.
// Angående hur en kWh värderas kan man antingen gå den officiella utsläppskoefficienten vi köper in (vattenkraft) som är på 8,23 g CO2/ kWh.
// Annars kan man gå på svensk elmix om 100 g CO2 / kWh(har för mig vi har beslutat om det senare).
// Då blir väl
// 1 kWh el = 100 g CO2 / kWh = 0,1 kg CO2 / kWh = 0,1 / 14 träd * år / kWh = 0,00714 träd * år / kWh el

// Wikipedia:
//   Wind direction is measured in degrees clockwise from true north.
//   Consequently, a wind blowing from the north has a wind direction of 0° (360°); a wind blowing from the east has a wind direction of 90°; a wind blowing from the south has a wind direction of 180°; and a wind blowing from the west has a wind direction of 270°.
