import React from "react";
import strings from "../strings/strings";
import getKeyValue from "../helpers/getKeyValue";
import SpecificationTable from "./SpecificationTable/SpecificationTable";

function ComparePanelSpecification(props) {
    const stringsPart = strings.comparePage.compareStepCompare.sunPanelsSpecificationPanel;

    const createPanels = (panels) => {
        return panels.map((panel) => ({
            panel: panel,
            name: getKeyValue(panel, "model"),
            image: panel.image,
        }));
    };

    const createPanelSpec = (panels) => {
        if (panels.length < 1) return [];
        return [
            {
                key: "Modellbeskrivning",
                value: [
                    {
                        key: stringsPart.solarPanelBrand,
                        value: panels.map((panel) => getKeyValue(panel, "brand")),
                    },
                    {
                        key: stringsPart.moduleType,
                        value: panels.map((panel) => getKeyValue(panel, "moduleType")),
                    },
                    {
                        key: stringsPart.cellType,
                        value: panels.map((panel) => getKeyValue(panel, "cellType")),
                    },
                    {
                        key: stringsPart.technology,
                        value: panels.map((panel) => getKeyValue(panel, "technology")),
                    },
                    {
                        key: stringsPart.busbars,
                        value: panels.map((panel) => getKeyValue(panel, "busbars")),
                    },
                ],
            },
            {
                key: "Ström",
                value: [
                    {
                        key: stringsPart.power,
                        value: panels.map((panel) => getKeyValue(panel, "power")),
                    },
                    {
                        key: stringsPart.efficiency,
                        value: panels.map((panel) => {
                            let val = getKeyValue(panel, "efficiency");
                            val = val.replace(",", ".");
                            val = (parseFloat(val.trim()) * 100).toFixed(1).toString() + "%";
                            return val;
                        }),
                    },
                ],
            },
            {
                key: "Mått",
                value: [
                    {
                        key: stringsPart.height,
                        value: panels.map((panel) => getKeyValue(panel, "height")),
                    },
                    {
                        key: stringsPart.width,
                        value: panels.map((panel) => getKeyValue(panel, "width")),
                    },
                    {
                        key: stringsPart.thickness,
                        value: panels.map((panel) => getKeyValue(panel, "thickness")),
                    },
                ],
            },
        ];
    };

    return (
        <div style={{ overflowX: "auto" }}>
            <div style={{ width: "100%" }}>
                {/*<SpecificationTable
                    title={stringsPart.propertiesHeader}
                    data={panelSpecificationPropNames}
                    isDataTable={false}
                /> */}
                {/*props.panels.map((p, i) => (
                    <SpecificationTable
                        key={i}
                        title={getKeyValue(p, "model")}
                        data={createPanelSpecificationData(p)}
                        isDataTable={true}
                    />
                ))*/}
                <SpecificationTable
                    empty="Inga valda paneler..."
                    allData={props.panels}
                    data={createPanelSpec(props.panels)}
                    typeData={createPanels(props.panels)}
                    dataType="obj"
                />
            </div>
        </div>
    );
}
export default ComparePanelSpecification;

