import React, { useEffect, useState } from "react";
import { Row } from "antd";
import strings from "../strings/strings";
import c11_3 from "../assets/images/c11_3.jpg";
import f15_1 from "../assets/images/f15_1.JPG";
import openDataImage from "../assets/images/opendata-image.png";
import StartPageCard from "../components/StartPageCard.js";
import MomentaneousValues from "../components/momentaneous-values/MomentaneousValues.js";
import HistoricalValues from "../components/historical-values/HistoricalValues.js";
import HtmlComponent from "../components/HtmlComponent.js";
import { navigateToOpendata } from "../AppLayout";
import EU_image from "../assets/images/europeiska_unionen.png";
import CompareTable from "../components/CompareTable/CompareTable";
import { getMomentaneousValues } from "../services/apiService";

const Home = () => {
    const refreshInterval = 5000;
    const [data, setData] = useState(undefined);
    const [renderCount, setRenderCount] = useState(0);

    useEffect(() => {
        const fetchData = () => {
            getMomentaneousValues().then((response) => {
                setData(response.data);
                setRenderCount((r) => r + 1);
            });
        };
        fetchData();
        const interval = window.setInterval(fetchData, refreshInterval);
        return () => window.clearInterval(interval);
    }, []);

    return (
        <div>
            <div className="home-text">
                <h1 className="h1 white-color">{strings.startPage.title}</h1>
                <Row style={{ marginBottom: "4rem" }}>
                    <HtmlComponent value={strings.startPage.bodyText.join(" ")}
                      />
                     <CompareTable />
                </Row>
            </div>

            <MomentaneousValues data={data} renderCount={renderCount} />
            <HistoricalValues data={data} renderCount={renderCount} />

            <Row gutter={16} style={{ marginBottom: "4em", marginTop: "4em" }}>
                <StartPageCard
                    name={strings.startPage.card.about.name}
                    title={strings.startPage.card.about.title}
                    image={c11_3}
                    detailText={strings.startPage.card.about.detailText}
                    buttonText={strings.startPage.card.about.buttonText}
                    buttonRoute="/about"
                />
                <StartPageCard
                    name={strings.startPage.card.compare.name}
                    title={strings.startPage.card.compare.title}
                    image={f15_1}
                    detailText={strings.startPage.card.compare.detailText}
                    buttonText={strings.startPage.card.compare.buttonText}
                    buttonRoute="/compare"
                />
                <StartPageCard
                    name={strings.startPage.card.openData.name}
                    title={strings.startPage.card.openData.title}
                    image={openDataImage}
                    detailText={strings.startPage.card.openData.detailText}
                    buttonText={strings.startPage.card.openData.buttonText}
                    buttonRoute="/open-data"
                    onClick={navigateToOpendata}
                />
            </Row>
            <Row
                style={{
                    border: "1px solid #d3d3d3",
                    borderRadius: "var(--borderrad)",
                    overflow: "hidden",
                    backgroundColor: "white",
                    marginBottom: "4em",
                }}
            >
                <div style={{ padding: "20px" }}>
                    <HtmlComponent value={strings.startPage.currentOutputText}></HtmlComponent>
                </div>
                <iframe
                    src="https://view.stuns.i0t.se/grafana/d-solo/CCsrWABZz/effekt?orgId=3&theme=light&panelId=2"
                    width="100%"
                    height="600"
                    frameBorder="0"
                    title="Effekt alla anläggningar"
                ></iframe>
            </Row>
            <Row
                style={{
                    border: "1px solid #d3d3d3",
                    borderRadius: "var(--borderrad)",
                    overflow: "hidden",
                    backgroundColor: "white",
                    marginBottom: "4em",
                }}
            >
                <div style={{ padding: "20px" }}>
                    <HtmlComponent value={strings.startPage.energyAllFacilities.join("")}></HtmlComponent>
                </div>
                <iframe
                    src="https://view.stuns.i0t.se/grafana/d-solo/QsKDAREZk/energi-dag?orgId=3&refresh=5m&theme=light&panelId=2"
                    width="100%"
                    height="600"
                    frameBorder="0"
                    title="Energi alla anläggningar"
                ></iframe>
            </Row>
            <Row
                style={{
                    border: "1px solid #d3d3d3",
                    borderRadius: "var(--borderrad)",
                    overflow: "hidden",
                    backgroundColor: "white",
                    marginBottom: "4em",
                }}
            >
                <div style={{ padding: "20px" }}>
                    <HtmlComponent value={strings.startPage.streetMapsText}></HtmlComponent>
                </div>
                <iframe
                    src="https://maps.stuns.se/stories?energiportalen=true"
                    width="100%"
                    height="600"
                    frameBorder="0"
                    title="Stuns Streetmaps"
                ></iframe>
            </Row>
            <Row
                style={{
                    border: "1px solid #d3d3d3",
                    borderRadius: "var(--borderrad)",
                    backgroundColor: "white",
                    marginBottom: "4em",
                    padding: "2em",
                }}
            >
                <div style={{ paddingBottom: "3rem" }}>
                    <h2 className="h2 grey-color mb-0">{strings.aboutPage.thirdSubTitle}</h2>
                    <img src={strings.aboutPage.financeImage1} alt="" style={{ paddingRight: "1rem" }}></img>
                    <img src={strings.aboutPage.financeImage2} alt="" style={{ paddingRight: "1rem" }}></img>
                </div>
                <div>
                    <h2 className="h2 grey-color mb-0">{strings.aboutPage.financeEU}</h2>
                    <img src={EU_image} alt=""></img>
                </div>
            </Row>

        </div>
    );
};

export default Home;
