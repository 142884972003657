import React, { useState, useEffect } from "react";
import CheckboxButton from "../CheckboxButton/CheckboxButton";

import "./CheckboxButtonList.scss";

function CheckboxButtonList(props) {
    const shouldHide = props.shouldHide ?? true;

    const [showMore, setShowMore] = useState(true);
    const [textVisible, setTextVisible] = useState(false);

    var _elem;

    useEffect(() => {
        if (showMore && !textVisible && shouldHide) {
            // Checks if the height exceeds the min-height and
            // if the height is three items too tall (one item ~34px tall)
            if (_elem.clientHeight >= 102 && _elem.clientHeight - 102 >= 102) {
                setShowMore(false);
                setTextVisible(true);
            }
        }
    }, [_elem, showMore, textVisible, shouldHide]);

    return (
        <div className="checkboxlist">
            <div
                className={"checkboxlist-items" + (!showMore ? " checkboxlist-items-hidden" : "")}
                ref={(el) => (_elem = el)}
                style={{
                    marginBottom: textVisible && showMore ? "var(--text-height)" : "0",
                }}
            >
                {props.data.map((cto) => (
                    <CheckboxButton
                        key={cto.value}
                        text={cto.value}
                        checked={false}
                        valueKey={cto.key}
                        value={cto.value}
                        onChange={props.checkboxChange}
                    />
                ))}
            </div>
            <span
                className="checkboxlist-showMore"
                style={{ display: textVisible ? "block" : "none" }}
                onClick={() => setShowMore(!showMore)}
            >
                {showMore ? "Visa mindre" : "Visa mer"}
            </span>
        </div>
    );
}
export default CheckboxButtonList;
