const createInvertersArray = (devices) => {
    const devicesWithSubDevices = devices.filter((d) => d.subDevices.length > 0);
    let subDevices = [];
    for (const device of devicesWithSubDevices) {
        subDevices.push(device.subDevices);
    }
    const flattenSubDevices = subDevices.flat();
    let inverters = [];
    let i = 1;
    for (const sub of flattenSubDevices) {
        const inverterModel = sub.properties.find((s) => s.key === "inverter model");
        if (inverterModel) {
            const model = inverterModel.value;
            // Sadly one model is named "Conected to north roof inverter"
            if (model === "Conected to north roof inverter") continue;
            if (model === null || (model === undefined) === "") continue;
            if (inverters.find((r) => r.properties.find((p) => p.value === model)) === undefined) {
                inverters.push({
                    id: i,
                    properties: [
                        { key: "model", value: model },
                        {
                            key: "brand",
                            value: sub.properties.find((s) => s.key === "inverter brand")?.value ?? "-",
                        },
                        {
                            key: "max efficiency [%]",
                            value: (() => {
                                return (
                                    parseFloat(
                                        (
                                            sub.properties.find((s) => s.key === "max efficiency [%]")?.value ?? "-"
                                        ).replace(",", ".")
                                    ) * 100
                                ).toFixed(0);
                            })(),
                        },
                        {
                            key: "euro efficiency [%]",
                            value: (() => {
                                return (
                                    parseFloat(
                                        (
                                            sub.properties.find((s) => s.key === "euro efficiency [%]")?.value ?? "-"
                                        ).replace(",", ".")
                                    ) * 100
                                ).toFixed(0);
                            })(),
                        },
                        {
                            key: "rated power [w]",
                            value: sub.properties.find((s) => s.key === "rated inverter power [w]")?.value ?? "-",
                        },
                    ],
                    image: `/inverters/${model}.jpg`,
                    displayName: model,
                });
                i++;
            }
        }
    }
    const sortedInverters = inverters.sort((a, b) =>
        a.properties.find((p) => p.key === "model").value > b.properties.find((p) => p.key === "model").value
            ? 1
            : b.properties.find((p) => p.key === "model").value > a.properties.find((p) => p.key === "model").value
            ? -1
            : 0
    );
    return sortedInverters;
};
export default createInvertersArray;
