import { Table } from 'antd';
import React, { useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { Link } from 'react-router-dom';
import { getDevices } from '../../services/apiService';
import "./compare-table.scss";

function clamp_range(value, min, max) {
  return Math.min(Math.max(value, min), max);
};
function map_range(value, low1, high1, low2, high2) {
  return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
}

function CompareTable() {

  const [isVisible, setIsVisible] = useState(false);

  const refreshInterval = 5000;

  const [data, setData] = useState(undefined);
  const [, setRenderCount] = useState(0);


  useEffect(() => {
      const fetchData = () => {
          getDevices().then((response) => {
              setData(response.data);
              setRenderCount((r) => r + 1);
          });
      };
      fetchData();
      const interval = window.setInterval(fetchData, refreshInterval);
      return () => window.clearInterval(interval);
  }, []); 

    const dataSource = [];
      if(data){
        for(let i = 0; i<data.length; i++){
          let fastighetIndex;
          let produktionÅrIndex;
          let installedPeakIndex;
          let produktionMånadIndex;
          for(let j = 0; j<data[i].properties.length; j++){
            if(data[i].properties[j].key === "display name") fastighetIndex = j;
            if(data[i].properties[j].key === "total yield during last year (kWh)") produktionÅrIndex = j;
            if(data[i].properties[j].key === "installed peak power [kwp]") installedPeakIndex = j;
            if(data[i].properties[j].key === "total yield during last month (kWh)") produktionMånadIndex = j;
          }
          if(data[i].identity && fastighetIndex && produktionÅrIndex && installedPeakIndex && produktionMånadIndex){
            dataSource.push({
              key:i,
              fastighet:data[i].properties[fastighetIndex].value, 
              fastighetIdentity:data[i].identity,
              installed_peak_power:data[i].properties[installedPeakIndex].value, 
              produktion_senaste_året:parseInt(data[i].properties[produktionÅrIndex].value),
              solenergi_per_kWp:parseInt(data[i].properties[produktionÅrIndex].value / data[i].properties[installedPeakIndex].value),
              produktion_senaste_månaden:parseInt(data[i].properties[produktionMånadIndex].value),
              solenergi_per_kWp_month:parseInt(data[i].properties[produktionMånadIndex].value / data[i].properties[installedPeakIndex].value),
            })
          }
        }
      }


      const columns = [
        {
          title: 'Fastighet',
          dataIndex: ['fastighetIdentity', 'fastighet'],
          key: 'fastighet',
          sortDirections: ['ascend', 'descend','ascend'],
          sorter: (a, b) => a.fastighet.localeCompare(b.fastighet),
          render: (text, row) => <Link to={"/about?device="+row['fastighetIdentity']}>{row['fastighet']}</Link>,
        },
        {
          title: 'Installerad Effekt [kWp]',
          dataIndex: 'installed_peak_power',
          key: 'installed peak power [kwp]',
          sortDirections: ['ascend', 'descend','ascend'],
          sorter:(a, b) => b.installed_peak_power - a.installed_peak_power,  
        },
        {
          title: 'Produktion senaste året [kWh]',
          dataIndex: 'produktion_senaste_året',
          sortDirections: ['ascend', 'descend','ascend'],
          key: 'produktion_senaste_året',
          sorter: (a, b) => b.produktion_senaste_året - a.produktion_senaste_året,  
        },
        {
            title: 'Solenergi per [kWh/kWp] (År)',
          dataIndex: 'solenergi_per_kWp',
          key: 'solenergi_per_kWp',
          sortDirections: ['ascend', 'descend','ascend'],
          defaultSortOrder : 'ascend' , 
          sorter: (a, b) => b.solenergi_per_kWp - a.solenergi_per_kWp,  
          render(text) {
            return {
              props: {
                style: { background: "hsl(" + clamp_range(map_range(text, 400, 1200, 0, 120), 0, 120) + " 100% 65%) "}
              },
              children: <div>{text}</div>
            };
          }
  
        },
        {
          title: 'Produktion senaste månaden [kWh]',
          dataIndex: 'produktion_senaste_månaden',
          key: 'produktion_senaste_månaden',
          sortDirections: ['ascend', 'descend','ascend'],
          defaultSortOrder : 'default' , 
          sorter: (a, b) => b.produktion_senaste_månaden - a.produktion_senaste_månaden,  
        },
        {
          title: 'Solenergi per [kWh/kWp] (Månad)',
          dataIndex: 'solenergi_per_kWp_month',
          key: 'solenergi_per_kWp_month',
          sortDirections: ['ascend', 'descend','ascend'],
          defaultSortOrder : 'default' , 
          sorter: (a, b) => b.solenergi_per_kWp_month - a.solenergi_per_kWp_month,  
          render(text) {
            return {
              
              props: {
                style: { background: "hsl(" + clamp_range(map_range(text, 0, 150, 0, 120), 0, 120) + " 100% 65%) "}
              },
              children: <div>{text}</div>
            };
          }
        },
      ];



      const columns_preview = [
        {
          title: 'Fastighet',
          dataIndex: ['fastighetIdentity', 'fastighet'],
          key: 'fastighet',
          render: (text, row) => <Link to={"/about?device="+row['fastighetIdentity']}>{row['fastighet']}</Link>,
        },
        {
          title: 'Installerad Effekt [kWp]',
          dataIndex: 'installed_peak_power',
          key: 'installed peak power [kwp]',
        },
        {
          title: 'Produktion senaste året [kWh]',
          dataIndex: 'produktion_senaste_året',
          key: 'produktion_senaste_året',
        },
        {
          title: 'Solenergi per [kWh/kWp] (År)',
          dataIndex: 'solenergi_per_kWp',
          key: 'solenergi_per_kWp',
          sortOrder : 'ascend' , 
          sortDirections: ['ascend', 'ascend'],
          sorter: (a, b) => b.solenergi_per_kWp - a.solenergi_per_kWp,  
          render(text) {
            return {
              props: {
                style: { background: "hsl(" + clamp_range(map_range(text, 400, 1200, 0, 120), 0, 120) + " 100% 65%) "}
              },
              children: <div>{text}</div>
            };
          }
  
        },
        {
          title: 'Produktion senaste månaden [kWh]',
          dataIndex: 'produktion_senaste_månaden',
          key: 'produktion_senaste_månaden',

      
        },
        {
          title: 'Solenergi per [kWh/kWp] (Månad)',
          dataIndex: 'solenergi_per_kWp_month',
          key: 'solenergi_per_kWp_month',
          
          render(text) {
            return {
              
              props: {
                style: { background: "hsl(" + clamp_range(map_range(text, 0, 150, 0, 120), 0, 120) + " 100% 65%) "}
              },
              children: <div>{text}</div>
            };
          }
        },
      ];

		return (
      <div> 
       
        <Table
            
              bordered
              dataSource={dataSource} 
              columns={columns_preview}
              pagination={{ pageSize: 3, disabled: true, total: 3}} 
              loading={data === undefined}
              footer={() =>   <div onClick={() => {
                setIsVisible(true)
              }} className="linkToTable">
               Klicka här för att se samtliga anläggningar!
              </div>}
             
             >
             
            </Table>
           
      <ReactModal
      onRequestClose={()=>{setIsVisible(false);}}
      className="facility-react-modal"
      isOpen={isVisible}
      onAfterOpen={() => (document.body.style.overflow = "hidden")}
      onAfterClose={() => (document.body.style.overflow = "unset")}
      style={{
          overlay: {
              zIndex: 1000,
              backgroundColor: "rgba(0, 0, 0, 0.45)",
              position: "fixed",
              height: "100vh",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              overflow: "auto",
          },
          content: {
              outline: "none",
              border: "none",
              backgroundColor: "white",
              padding: "0",
              margin: "auto",
              width: (() => {
                  let w = "100%";             
                  return w;
              })(),
              maxWidth:false ? "initial" : "1200px",
              height: false ? "100%" : "max-content",
              position: "relative",
              top: "10px",
              left: "0",
              right: "0",
              bottom: "10px",
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              borderRadius: false ? "0" : "var(--borderrad)",
          },
      }}
  >
            <div className='table-container' id="table-container">
            <Table

            bordered
              dataSource={dataSource} 
              columns={columns}
              pagination={false}
              loading={data === undefined}
              title={() =>   <div className='headerTable'> <span className='headerText'>Tabell - Solanläggningar</span><button className="CloseModalButton" onClick={() => {
                setIsVisible(false)
                }}> ⨯ </button>
                </div>}
             >
             
            </Table>
           
            </div>
         
            </ReactModal>
            </div>
		);
	}
    

export default CompareTable;

