import React, { useState } from "react";
import { Row, Col } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons/lib";
import IsTabletOrMobile from "../components/IsTabletOrMobile.js";
import strings from "../strings/strings.json";

function ComparePanelCard(props) {
    const [checked, setChecked] = useState(props.chosen);
    const isTablet = IsTabletOrMobile().tablet;

    const onClick = () => {
        if (checked) {
            setChecked(false);
            props.onChange({ id: props.id, checked: false });
        } else {
            setChecked(true);
            props.onChange({ id: props.id, checked: true });
        }
    };

    const stringCheck = (value) => {
        if (value === undefined || value === null || value === "") return "-";
        return value;
    };

    return (
        <Col span={8} className="compare-card">
            <div
                onClick={onClick}
                onChange={(e) => {
                    props.onChange(e);
                }}
                style={{ position: "relative" }}
                className={props.chosen ? "checked panel-style panel-card" : "notChecked panel-style panel-card"}
            >
                <Row justify="center">
                    {props.image === undefined ? (
                        <span className="facility-card-img">Bild kommer snart</span>
                    ) : (
                        <img
                            className="facility-card-img"
                            src={isTablet ? props.smallImage : props.image}
                            alt={props.model}
                        ></img>
                    )}
                </Row>
                <span className="black-color" style={{ fontSize: "14px" }}>
                    {stringCheck(props.brand)}
                </span>
                <br></br>
                <span className="black-color wordWrap h3">{stringCheck(props.model)}</span>
                <br></br>
                <span className="black-color wordWrap h6">
                    {strings.comparePage.cellType}: {stringCheck(props.cellType)}
                </span>
                <br></br>
                <span className="black-color wordWrap h6">
                    {strings.comparePage.cellTech}: {stringCheck(props.tech)}
                </span>
                {props.chosen ? (
                    <Row justify="end" style={{ position: "absolute", bottom: "15px", right: "15px" }}>
                        <CheckCircleOutlined style={{ color: "#1890ff", fontSize: "24px" }} />
                    </Row>
                ) : (
                    <></>
                )}
            </div>
        </Col>
    );
}

export default ComparePanelCard;
