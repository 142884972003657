export default function DateConverter(date) {
    function daysInAYear(y) {
        return y % 400 === 0 || (y % 100 !== 0 && y % 4 === 0) ? 366 : 365;
    }
    function getDays(totalDays, startYear, currYear, startMonth) {
        let daysNew = totalDays;
        for (var i = 0; i < currYear - startYear; i++) {
            const days = daysInAYear(startYear + i + 1);
            if (daysNew - days < 0) break;
            daysNew -= days;
        }
        return daysNew % new Date(dateYear, startMonth, 0).getDate();
    }

    // Define the months into Array numbers 0-11
    const monthNames = ["jan", "feb", "mar", "apr", "maj", "jun", "jul", "aug", "sep", "oct", "nov", "dec"];

    const dateYear = parseInt("20" + date.split("-")[2]);
    const dateMonth = date.split("-")[1];
    const dateMonthIndex = parseInt(monthNames.findIndex((e) => e === dateMonth.toLowerCase()));
    // Create a varaible with the previous values stored in correct order for JavaScript Date() functions to handle
    const activationDate = new Date(dateYear, dateMonthIndex, date.split("-")[0]);
    const currDate = new Date();
    const daysInCurrentYear = daysInAYear(currDate.getFullYear());

    const delta = currDate.getTime() - activationDate.getTime();
    // diff / ms / sec/ min / hours
    const totalDays = Math.floor(delta / 1000 / 60 / 60 / 24);

    const years = Math.floor(totalDays / daysInCurrentYear);
    const months = Math.floor(totalDays / (daysInCurrentYear / 12)) % 12;
    const days = getDays(totalDays, dateYear, currDate.getFullYear(), dateMonthIndex);

    const returnString = [];
    if (years > 0) returnString.push(`${years} år`);
    if (months > 0) returnString.push(`${months} månader`);
    if (days > 0) returnString.push(`${days} dagar`);

    return returnString.join(", ");
}
