import React, { useState, useEffect } from "react";
import { Switch } from "react-router-dom";
import Home from "./views/Home";
import About from "./views/About";
import Compare from "./views/Compare";
import OpenData from "./views/OpenData";
import { getDevices } from "./services/apiService";
import ParameterValueDifferences from "./views/ParameterValueDifferences";
import ScrollToTopRoute from "./ScrollToTopRoute";
import createPanelArray from "./helpers/createPanelArray.js";
import createInvertersArray from "./helpers/createInvertersArray";

function AppRoutes() {
    const [devices, setDevices] = useState(undefined);
    const [panels, setPanels] = useState(undefined);
    const [inverters, setInverters] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        // Adds "solar panel brand", "solar cell type" and "solar cell technology" to the devices properties
        const createDeviceArray = (sortedDevices) => {
            for (const device of sortedDevices) {
                let newValue = device.properties
                    .find((val) => val.key === "solar panel brand")
                    .value.toString()
                    .split(",")
                    .map((val) => val.trim());
                // Checks for duplicates
                newValue = newValue.filter((val, i) => newValue.indexOf(val) === i);
                device.properties.find((val) => val.key === "solar panel brand").value = newValue;

                const subD_props = ["solar cell type", "solar cell technology", "azimuth", "roof angle", "roof type"];
                for (const property of subD_props) {
                    const values = device.subDevices.map(
                        (subD) => subD.properties.find((val) => val.key === property).value
                    );
                    device.properties.push({
                        key: property,
                        value: values.filter((val, i) => values.indexOf(val) === i),
                    });
                }
                let img = device.properties.find((v) => v.key === "image urls")?.value;
                device.image = !img ? undefined : img.split(";")[0];

                if (device.displayName === null) {
                    device.displayName = device.properties.find((v) => v.key === "site")?.value;
                }
            }
            return sortedDevices;
        };
        const fetchDevices = () => {
            getDevices().then((res) => {
                setIsLoading(true);
                // if a device does not contain the property "site" then it is considered empty
                const allDevices = res.data.filter((d) => d.properties.find((p) => p.key === "site"));
                const sortedDevices = allDevices.sort((a, b) =>
                    a.properties.find((p) => p.key === "display name").value >
                    b.properties.find((p) => p.key === "display name").value
                        ? 1
                        : b.properties.find((p) => p.key === "display name").value >
                          a.properties.find((p) => p.key === "display name").value
                        ? -1
                        : 0
                );
                setDevices(createDeviceArray(sortedDevices));

                const sunPanels = createPanelArray(res.data);
                setPanels(sunPanels);

                setInverters(createInvertersArray(res.data));

                setIsLoading(false);
            });
        };
        fetchDevices();
    }, []);

    return (
        <Switch>
            <ScrollToTopRoute exact path="/">
                {(props) => <Home history={props.history} />}
            </ScrollToTopRoute>
            <ScrollToTopRoute path="/about">
                {(props) => <About devices={devices} isLoading={isLoading} history={props.history} />}
            </ScrollToTopRoute>
            <ScrollToTopRoute path="/compare">
                {(props) => (
                    <Compare
                        devices={devices}
                        panels={panels}
                        inverters={inverters}
                        isLoading={isLoading}
                        history={props.history}
                    />
                )}
            </ScrollToTopRoute>
            <ScrollToTopRoute path="/open-data">{(props) => <OpenData history={props.history} />}</ScrollToTopRoute>
            <ScrollToTopRoute path="/parameter-value-differences">
                {(props) => <ParameterValueDifferences history={props.history} />}
            </ScrollToTopRoute>
        </Switch>
    );
}

export default AppRoutes;
