import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { createParameterDefinitions } from "./logic.js";
import IsTabletOrMobile from "../IsTabletOrMobile.js";

import strings from "../../strings/strings";

import "./historical-values.scss";

const HistoricalValues = ({ data, renderCount, parameters }) => {
    const previousRef = useRef(undefined);

    const [parameterDefinitions] = useState(createParameterDefinitions());
    const isMobile = IsTabletOrMobile().mobile;

    const parametersToShow = parameters || [
        ["totalYield", "totalNumberOfTrees"],
        ["last365DaysYield", "yearNumberOfTrees"],
        ["last30DaysYield", "monthNumberOfTrees"],
        ["dayYield", "dayNumberOfTrees"],
    ];

    const dataTime = ["Totalt", "Senaste 365 dagarna", "Senaste 30 dagarna", "Under dagen"];

    if (!data) return false;
    return (
        <div
            className="p-1 start-page-card"
            style={{
                backgroundColor: "white",
                marginTop: "50px",
            }}
        >
            <h2 className="h2 black-color">{strings.startPage.totalProduction}</h2>
            <div
                className="parameter-box-container"
                style={{
                    color: "black",
                    justifyContent: "space-evenly",
                }}
            >
                {parametersToShow.map((section, i) => {
                    return (
                        <React.Fragment key={i}>
                            <div
                                style={{
                                    width: isMobile ? "100%" : "45%",
                                }}
                            >
                                <h2 style={{ display: "block", textAlign: "center", margin: "1em 0 0 0" }}>
                                    {dataTime[i]}
                                </h2>
                                <div
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        width: "100%",
                                        justifyContent: "space-evenly",
                                    }}
                                >
                                    {section.map((parameter, index) => {
                                        const parameterDefinition = parameterDefinitions[parameter];
                                        const parameterValue = parameterDefinition.getValue
                                            ? parameterDefinition.getValue(data)
                                            : data[parameter];
                                        const title_defined =
                                            parameterDefinition.title === "" || parameterDefinition.title === undefined;
                                        const title = title_defined ? "-" : parameterDefinition.title;
                                        return (
                                            <div
                                                key={title + index}
                                                className="parameter-box"
                                                style={{
                                                    width: "max-content",
                                                    maxWidth: "150px",
                                                    height: "max-content",
                                                }}
                                                title={parameterDefinition.description}
                                            >
                                                <span
                                                    style={{
                                                        width: "100%",
                                                        display: "block",
                                                        textAlign: "center",
                                                        marginBlock: "1em",
                                                    }}
                                                >
                                                    {title}
                                                </span>
                                                <img
                                                    src={parameterDefinition.icon}
                                                    alt={parameterDefinition.description}
                                                    className="icon"
                                                    style={
                                                        parameterDefinition.getIconStyle
                                                            ? parameterDefinition.getIconStyle(
                                                                  parameterValue,
                                                                  previousRef,
                                                                  renderCount
                                                              )
                                                            : undefined
                                                    }
                                                />
                                                <div className="value">
                                                    {parameterDefinition.formatValue(parameterValue)}
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                            {parametersToShow.length - 1 > i && (
                                <div
                                    style={{
                                        backgroundColor: "black",
                                        width: isMobile ? "70%" : "1px",
                                        height: isMobile ? "1px" : "100px",
                                        margin: isMobile ? "1em 0" : "",
                                        display: i % 2 !== 0 && !isMobile ? "none" : "block",
                                    }}
                                ></div>
                            )}
                        </React.Fragment>
                    );
                })}
            </div>
        </div>
    );
};

HistoricalValues.propTypes = {
    data: PropTypes.object,
    renderCount: PropTypes.number,
    parameters: PropTypes.array
};

export default HistoricalValues;
