import React, { useState } from "react";
import fileDownload from "js-file-download";
import { sub, startOfHour, format } from "date-fns";
import queryString from "query-string";

import { Typography, Space, Button, Alert } from "antd";
import DatePicker from "../date-picker/DatePicker";

const apiUrl = "https://stunssolar.azurewebsites.net/api/download-blobs";
const dateTimeFormat = "yyyy-MM-dd HH:00";

const BlobDownload = () => {
    const initialTimeInterval = {
        from: sub(startOfHour(new Date()), { hours: 1 }),
        to: startOfHour(new Date()),
    };

    const [timeInterval, setTimeInterval] = useState(initialTimeInterval);
    const [isDownloading, setIsDownloading] = useState(false);
    const [errorMessage, setErrorMessage] = useState(undefined);

    const isDisabled = () => {
        return !timeInterval;
    };

    const handleTimeIntervalChange = (args) => {
        setTimeInterval(args ? { from: args[0], to: args[1] } : undefined);
    };

    const handleSubmit = () => {
        setIsDownloading(true);

        const headers = {};
        headers["Access-Control-Allow-Origin"] = "*";

        const parameters = {
            from: timeInterval.from.toISOString(),
            to: timeInterval.to.toISOString(),
        };

        const url = apiUrl + "?" + queryString.stringify(parameters);

        fetch(url, headers)
            .then((response) => {
                if (response.status === 200) {
                    return response.blob();
                }
                return undefined;
            })
            .then((blob) => {
                setIsDownloading(false);
                if (blob) {
                    const fileName =
                        format(timeInterval.from, "yyyy-MM-dd-HH-mm") +
                        "--" +
                        format(timeInterval.to, "yyyy-MM-dd-HH-mm") +
                        ".zip";
                    fileDownload(blob, fileName, "application/zip");
                } else {
                    setErrorMessage("Felaktig förfrågan. Du skrev troligen in ett felaktigt tidsspann.");
                }
            });
    };

    return (
        <Space direction="vertical">
            <Typography.Text>Här kan du ladda ner rådata i JSON-format från våra anläggningar.</Typography.Text>

            <Typography.Text>Tidsintervall</Typography.Text>
            <DatePicker.RangePicker
                defaultValue={[initialTimeInterval.from, initialTimeInterval.to]}
                format={dateTimeFormat}
                showTime={{ format: "HH" }}
                disabled={isDownloading}
                disabledDate={(date) => date > new Date()}
                onChange={handleTimeIntervalChange}
            />
            <Typography.Text>Observera att tidsspannet inte får vara längre än sex timmar.</Typography.Text>

            <Button type="primary" loading={isDownloading} onClick={handleSubmit} disabled={isDisabled()}>
                {isDownloading ? "Laddar ner" : "Ladda ner"}
            </Button>
            {errorMessage && <Alert type="error" message={errorMessage} />}
        </Space>
    );
};

export default BlobDownload;
