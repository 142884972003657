import React from "react";
import { Button, Row, Col } from "antd";
import { useHistory } from "react-router-dom";
import IsTabletOrMobile from "./IsTabletOrMobile";

function StartPageCard(props) {
    const history = useHistory();

    function clickCallback(e) {
        props.onClick === undefined ? history.push(props.buttonRoute) : props.onClick(e);
    }

    return (
        <Col
            span={8}
            className="start-column"
            style={{
                cursor: "pointer",
            }}
            onClick={clickCallback}
        >
            <div
                className="start-page-card bg-white p-1"
                style={{
                    height: IsTabletOrMobile.mobile ? "100%" : "initial",
                    display: "flex",
                    flexFlow: "column",
                    justifyContent: "space-between",
                    alignItems: "center",
                    minHeight: "100%",
                }}
            >
                <div style={{ width: "100%" }}>
                    <span className="h4 black-color">{props.name}</span>
                    <h1 className="h2 black-color">{props.title}</h1>
                    <Row>
                        <img
                            src={props.image}
                            alt=""
                            style={{
                                height: "150px",
                                maxWidth: "100%",
                                minWidth: "100%",
                                borderRadius: "15px",
                                objectFit: "cover",
                            }}
                        ></img>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <hr
                                style={{
                                    width: "50%",
                                    float: "left",
                                    height: "1px",
                                    border: "none",
                                    color: "#171A20",
                                    backgroundColor: "#171A20",
                                    marginTop: "15px",
                                }}
                            />
                        </Col>
                    </Row>
                    <p style={{ color: "#171A20", minHeight: "5em" }}>{props.detailText}</p>
                </div>
                <Button onClick={clickCallback} className="button-secondary" shape="round" style={{ width: "70%" }}>
                    {props.buttonText}
                </Button>
            </div>
        </Col>
    );
}

export default StartPageCard;
