import React, { useEffect, useState } from "react";
import { Row, Button } from "antd";
import CheckboxButtonList from "./CheckboxButtonList/CheckboxButtonList";
import CompareSlider from "./CompareSlider/CompareSlider";
import strings from "../strings/strings.json";

function FilterOptions(props) {
    const [allFilterOptions, setAllFilterOptions] = useState([]);
    const [usedFilterOptions, setUsedFilterOptions] = useState([]);
    const [reset, setReset] = useState(false);

    const onlyUnique = (val, index, self) => {
        if (val !== undefined) {
            return index === self.findIndex((t) => t !== undefined && t.value === val.value && t.value !== "-");
        }
    };

    const onlyUniqueByDeviceId = (val, index, self) => {
        if (val !== undefined) {
            return index === self.findIndex((t) => t.deviceId === val.deviceId);
        }
    };

    const onlyUniqueByPanelId = (val, index, self) => {
        if (val !== undefined) {
            return index === self.findIndex((t) => t.id === val.id);
        }
    };

    useEffect(() => {
        if (props.items) {
            let optionArray = [];
            for (const option of props.options) {
                let filterOptions = {
                    valueKey: option.value,
                    type: option.type,
                    dataType: option.dataType,
                    sliderInc: option.sliderInc,
                    values: props.items.map((d) => d.properties.find((p) => p.key === option.value)),
                    shouldHide: option.shouldHide,
                };
                if (option.type === "range") {
                    const getVal = (v) => {
                        let value;
                        if (option.dataType === "array") {
                            value = filterOptions.values.map((val) => val.value.map((v) => parseInt(v))).flat(1);
                        } else {
                            value = v === undefined ? 0 : parseInt(v.value);
                        }
                        return value;
                    };
                    filterOptions.values = filterOptions.values.map((v) => ({
                        key: option.value,
                        value: getVal(v),
                    }));
                }
                if (option.dataType === "array") {
                    let op = filterOptions.values.map((val) => val?.value).flat(1);
                    op = op.filter((val, i) => op.indexOf(val) === i);
                    filterOptions.values = op.map((v) => ({
                        key: option.value,
                        value: v,
                    }));
                } else {
                    filterOptions.values = filterOptions.values.filter(onlyUnique);
                }
                optionArray.push(filterOptions);
            }
            setAllFilterOptions(optionArray);
        }
    }, [props.items, props.options]);

    const filterItems = (o) => {
        let filters = {};
        for (const option of o) {
            if (filters[option.key] === undefined) filters[option.key] = { type: option.type, values: [] };
            if (typeof option.value === typeof []) {
                filters[option.key].values.push(...option.value);
            } else {
                filters[option.key].values.push(option.value);
            }
        }
        // Filters all items
        let filteredItems = props.items.filter((item) => {
            let returnVal = [];
            for (const [key, obj] of Object.entries(filters)) {
                const filterValues = obj.values;
                const type = obj.type;
                let value = item.properties.find((p) => p.key === key)?.value;
                // Includes this item if undefined just like other comparison sites with missing information
                if (value === undefined) continue;
                if (typeof value === typeof []) {
                    if (type === "multiple") {
                        returnVal.push(value.map((op) => filterValues.includes(op)).includes(true));
                    } else if (type === "range") {
                        let valList = [];
                        for (const v of value) {
                            let vInt = parseInt(v);
                            valList.push(vInt >= filterValues[0] && vInt <= filterValues[1]);
                        }
                        returnVal.push(valList.includes(true));
                    }
                } else {
                    if (type === "range") {
                        value = parseInt(value);
                        returnVal.push(value >= filterValues[0] && value <= filterValues[1]);
                    } else if (type === "multiple") {
                        returnVal.push(filterValues.includes(value));
                    }
                }
            }
            return !returnVal.includes(false);
        });

        const onlyUniqueItemsAfterFilter = filteredItems
            .flat()
            .filter(props.filterPanels ? onlyUniqueByPanelId : onlyUniqueByDeviceId);
        return onlyUniqueItemsAfterFilter;
    };

    const initFilterItems = (options) => {
        const onlyUniqueItemsAfterFilter = filterItems(options);
        props.onChange(onlyUniqueItemsAfterFilter === undefined ? [] : onlyUniqueItemsAfterFilter);
    };

    const checkboxChange = (value, checked, valueKey) => {
        let options = usedFilterOptions;
        if (checked) {
            options.push({ key: valueKey, type: "multiple", value: value });
        } else {
            options = options.filter((o) => o.value !== value);
        }
        setUsedFilterOptions(options);
        initFilterItems(options);
    };

    const rangeChange = (min, max, valueKey) => {
        let options = usedFilterOptions;
        if (options.find((v) => v.key === valueKey) === undefined) {
            options.push({ key: valueKey, type: "range", value: [min, max] });
        } else {
            options.find((v) => v.key === valueKey).value = [min, max];
        }
        setUsedFilterOptions(options);
        initFilterItems(options);
    };

    return (
        <div className="width-100">
            <Button
                className="button-secondary width-100"
                shape="round"
                onClick={() => {
                    setReset(!reset);
                    setUsedFilterOptions([]);
                    props.cleanFunc();
                }}
                disabled={usedFilterOptions.length < 1}
            >
                {strings.comparePage.cleanFilters}
            </Button>
            <div key={reset}>
                {allFilterOptions.map((o, ix) => (
                    <Row key={ix}>
                        <span className="grey-color h5" style={{ marginTop: "10px" }}>
                            {props.options[ix] ? props.options[ix].name : ""}
                        </span>
                        {o.type === "range" ? (
                            <div
                                style={{
                                    width: "100%",
                                }}
                            >
                                <CompareSlider
                                    key={o.valueKey.split(" ").join("")}
                                    values={o}
                                    onAfterChange={(min, max) => rangeChange(min, max, o.valueKey)}
                                />
                            </div>
                        ) : (
                            <CheckboxButtonList
                                checkboxChange={checkboxChange}
                                data={o.values}
                                shouldHide={o.shouldHide}
                            />
                        )}
                    </Row>
                ))}
            </div>
        </div>
    );
}
export default FilterOptions;
