import React, { useEffect, useState } from "react";
import "antd/dist/antd.min.css"
import "./index.css";
import "./index.scss";
import { ConfigProvider, Layout, Menu, Row, Col, Button } from "antd";
import locale from "antd/lib/locale/sv_SE";
import { Link, NavLink } from "react-router-dom";
import logo from "./assets/images/regionuppsala_logo.svg";
import strings from "./strings/strings";
import AppRoutes from "./AppRoutes";
import IsTabletOrMobile from "./components/IsTabletOrMobile.js";
import { MenuUnfoldOutlined, MenuFoldOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import CookieConsent from "react-cookie-consent";

const { Header, Content } = Layout;

// a hack, see https://github.com/ant-design/ant-design/issues/26699
locale.DatePicker.lang.locale = "sv";

// Hacky solution that forces the entryscape script to load correctly
export function navigateToOpendata(event) {
    event.preventDefault();
    const href = window.location.href;
    window.location.href = href.substr(0, href.lastIndexOf("/")) + "/open-data";
}

function AppLayout() {
    const [current, setCurrent] = useState();
    const [collapsed, setCollapsed] = useState(true);
    const [showEasterEgg, setShowEasterEgg] = useState(false);
    const [easterEggKeyPresses, setEasterEggKeyPresses] = useState([]);
    const isMobile = IsTabletOrMobile().mobile;
    const location = useLocation();

    useEffect(() => {
        setCurrent(location.pathname);
    }, [location.pathname]);

    const handleClick = (e) => {
        setCurrent(e.key);
        setCollapsed(true);
    };

    const toggleCollapsed = () => {
        setCollapsed(!collapsed);
    };

    const easterEggInit = () => {
        const correct = "eastertime";
        window.onkeypress = (event) => {
            if (event.target.tagName === "INPUT") return;
            const key = event.key.toLowerCase();
            const previewValue = easterEggKeyPresses.join("") + key;
            if (previewValue === correct.substr(0, previewValue.length)) {
                if (previewValue.length === correct.length) setShowEasterEgg(true);
            } else {
                setEasterEggKeyPresses([]);
                return;
            }
            setEasterEggKeyPresses(easterEggKeyPresses.concat(key));
        };
    };
    easterEggInit();

    function getMenuItems() {
        return (
            <>
                <Menu.Item key="/">
                    <NavLink to="/">{strings.menu.index}</NavLink>
                </Menu.Item>
                <Menu.Item key="/about">
                    <NavLink to="/about">{strings.menu.about}</NavLink>
                </Menu.Item>
                <Menu.Item key="/compare">
                    <NavLink to="/compare">{strings.menu.compare}</NavLink>
                </Menu.Item>
                <Menu.Item key="/openData">
                    <NavLink to="/open-data" onClick={navigateToOpendata}>
                        {strings.menu.openData}
                    </NavLink>
                </Menu.Item>
            </>
        );
    }

    return (
        <ConfigProvider locale={locale}>
            <Layout style={{ cursor: showEasterEgg ? 'url("./easter_cursor.png"), default' : "default" }}>
                <Header style={{ display: "flex", justifyContent: "center" }}>
                    <img
                        src={logo}
                        alt="stuns logo"
                        className="menu-logotype"
                        style={{ height: "40px", left: "0", top: "12px", position: "absolute", padding: "0 1em" }}
                    ></img>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            width: "100%",
                            maxWidth: "1024px",
                        }}
                    >
                        <Menu
                            mode="horizontal"
                            style={{ display: "inline-block", position: isMobile ? "absolute" : "static" }}
                        >
                            
                        </Menu>
                        {isMobile ? (
                            <Row justify="end" style={{ positon: "absolute", width: "100%" }}>
                                <Button type="primary" onClick={toggleCollapsed} className="mobile-menu-btn">
                                    {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined)}
                                </Button>
                                <Menu
                                    className={collapsed ? "collapsed-menu" : "open-menu"}
                                    onClick={handleClick}
                                    selectedKeys={[current]}
                                    mode="inline"
                                    inlineCollapsed={collapsed}
                                >
                                    {getMenuItems()}
                                </Menu>
                            </Row>
                        ) : (
                            <Menu
                            key={1}
                                onClick={handleClick}
                                selectedKeys={[current]}
                                disabledOverflow={true}
                                mode="horizontal"
                                style={{
                                    position: "relative",
                                    justifyContent: "center",
                                    display: "inline-block",
                                }}
                            >
                                {getMenuItems()}
                            </Menu>
                        )}
                    </div>
                </Header>
                <Layout>
                    <Content style={{ minHeight: "calc(100vh - 70px)", paddingTop: "100px" }}>
                        <Row justify="center">
                            <Col
                                span={22}
                                style={{
                                    maxWidth: location.pathname === "/compare" ? "1350px" : "1024px",
                                    width: "100%",
                                }}
                            >
                                <AppRoutes />
                            </Col>
                        </Row>
                    </Content>
                </Layout>
                <footer>
                    <div className="footer-page">
                        <div className="footer-col">
                        <span>{"Hitta runt på sidan"}</span>
                            <Link to="/" className="grey-color">
                            {strings.footer.name}
                            </Link>
                            <Link to="/about" className="grey-color">
                            {strings.footer.about}
                            </Link>
                            <Link to="/compare" className="grey-color">
                            {strings.footer.compare}
                            </Link>
                            <Link to="/open-data" className="grey-color">
                            {strings.footer.openData}
                            </Link>
                        </div>
                        <div className="footer-col">
                        <span>{"Information Region Uppsala"}</span>
                            <a href="https://regionuppsala.se/" className="grey-color">
                            {strings.footer.regionUppsala}
                            </a>
                        <a
                            href="https://regionuppsala.se/det-har-gor-vi/om-region-uppsala/organisation/fastighetochservice/"
                            className="grey-color"
                        >
                            {strings.footer.realEstateAndService}
                        </a>
                        </div>
                        <div className="footer-col">
                        <span>{"Läs mer om vår data nedan"}</span>
                        <a
                            href="https://www.dataportal.se/en/datasets/763_1492/solar-power-production-region-uppsala-solar-power-plants-1-minute-resolution#ref=?p=1&q=stuns%20&s=2&t=20&f=&rt=dataset%24esterms_IndependentDataService%24esterms_ServedByDataService"
                            className="grey-color"
                        >
                            {strings.footer.dataPortalProduction}
                        </a>
                        <a
                            href="https://www.dataportal.se/en/datasets/763_333/list-of-solar-power-plants-at-region-uppsala-real-estates#ref=?p=1&q=stuns%20energi&s=2&t=20&f=&rt=dataset%24esterms_IndependentDataService%24esterms_ServedByDataService"
                            className="grey-color"
                        >
                            {strings.footer.dataPortalFacilities}
                        </a>
                        </div>
                        <div className="footer-col">
                        <span>{"Information Stuns"}</span>
                            <a href="https://stuns.se/" className="grey-color">
                            {strings.footer.stunsFooter}
                            </a>
                            <a href="https://stunsenergi.se/" className="grey-color">
                            {strings.footer.stunsEnergyFooter}
                            </a>
                        </div>
                    </div>
                </footer>
                <CookieConsent
                    buttonText={strings.iUnderstandCookies}
                    buttonStyle={{ color: "#ffffff", backgroundColor: "#1890ff" }}
                >
                    {strings.cookieInformation}
                </CookieConsent>
            </Layout>
        </ConfigProvider>
    );
}

export default AppLayout;
