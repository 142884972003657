import React from "react";
import strings from "../strings/strings";
import getKeyValue from "../helpers/getKeyValue";
import SpecificationTable from "./SpecificationTable/SpecificationTable";

function ComparePanelSpecification(props) {
    const stringsPart = strings.comparePage.compareStepCompare.sunPanelsSpecificationPanel;

    const createInverters = (inverters) => {
        return inverters.map((inverter) => ({
            inverters: inverter,
            name: getKeyValue(inverter, "model"),
            image: inverter.image,
        }));
    };

    const createPanelSpec = (inverters) => {
        if (inverters.length < 1) return [];
        return [
            {
                key: "Modellbeskrivning",
                value: [
                    {
                        key: stringsPart.solarPanelBrand,
                        value: inverters.map((inverter) => getKeyValue(inverter, "brand")),
                    },
                ],
            },
            {
                key: "Ström",
                value: [
                    {
                        key: "Max efficiency [%]",
                        value: inverters.map((inverter) => {
                            return getKeyValue(inverter, "max efficiency [%]") + "%";
                        }),
                    },
                    {
                        key: "Euro efficiency [%]",
                        value: inverters.map((inverter) => {
                            return parseFloat(getKeyValue(inverter, "euro efficiency [%]").replace(",", ".")) + "%";
                        }),
                    },
                    {
                        key: stringsPart.power,
                        value: inverters.map((inverter) => getKeyValue(inverter, "rated power [w]") + "W"),
                    },
                ],
            },
        ];
    };

    return (
        <div style={{ overflowX: "auto" }}>
            <div style={{ width: "100%" }}>
                <SpecificationTable
                    empty="Inga valda växelriktare..."
                    allData={props.inverters}
                    data={createPanelSpec(props.inverters)}
                    typeData={createInverters(props.inverters)}
                    dataType="obj"
                />
            </div>
        </div>
    );
}
export default ComparePanelSpecification;
