import React, { useState, useEffect } from "react";
import { Row, Col, Spin, Tabs, Button, Collapse, Typography } from "antd";
import strings from "../strings/strings";
import CompareFacilityCard from "../components/CompareFacilityCard.js";
import ComparePanelCard from "../components/ComparePanelCard.js";
import CompareInverterCard from "../components/CompareInverterCard";
import CheckboxButton from "../components/CheckboxButton/CheckboxButton.js";
import FilterOptions from "../components/FilterOptions.js";
import IsTabletOrMobile from "../components/IsTabletOrMobile.js";
import getKeyValue from "../helpers/getKeyValue";
import CompareDeviceSpecification from "../components/CompareDeviceSpecification.js";
import ComparePanelSpecification from "../components/ComparePanelSpecification.js";
import CompareInverterSpecification from "../components/CompareInverterSpecification";
import HtmlComponent from "../components/HtmlComponent";

const { TabPane } = Tabs;
const { Panel } = Collapse;

function Compare(props) {
    const [filteredDevices, setFilteredDevices] = useState(props.devices);
    const [filteredPanels, setFilteredPanels] = useState(props.panels);
    const [filteredInverters, setFilteredInverters] = useState(props.inverters);

    const [chosenDevices, setChosenDevices] = useState([]);
    const [notChosenDevices, setNotChosenDevices] = useState([]);

    const [chosenPanels, setChosenPanels] = useState([]);
    const [notChosenPanels, setNotChosenPanels] = useState([]);

    const [chosenInverters, setChosenInverters] = useState([]);
    const [notChosenInverters, setNotChosenInverters] = useState([]);

    const [activeTab, setActiveTab] = useState("1");
    const [step, setStep] = useState(1);
    const isMobile = IsTabletOrMobile().mobile;

    const [compareMin, compareMax] = [2, 4];

    useEffect(() => {
        setFilteredDevices(props.devices);
        setFilteredPanels(props.panels);
        setFilteredInverters(props.inverters);
    }, [props.devices, props.panels, props.inverters, activeTab]);

    const handleChosenDevicesChange = (event) => {
        if (chosenDevices.length > 0 && chosenDevices.find((d) => d.deviceId === event.id) && !event.checked) {
            removeChosenDevice(event.id);
        } else {
            if (chosenDevices.length > compareMax - 1) {
                alert(strings.comparePage.tooManyItemsDevices);
                return;
            }
            addChosenDevice(event.id);
        }
    };

    const handleChosenPanelsChange = (event) => {
        if (chosenPanels.length > 0 && chosenPanels.find((p) => p.id === event.id) && !event.checked) {
            removeChosenPanel(event.id);
        } else {
            if (chosenPanels.length > compareMax - 1) {
                alert(strings.comparePage.tooManyItemsPanels);
                return;
            }
            addChosenPanel(event.id);
        }
    };

    const handleChosenInvertersChange = (event) => {
        if (chosenInverters.length > 0 && chosenInverters.find((p) => p.id === event.id) && !event.checked) {
            removeChosenInverter(event.id);
        } else {
            if (chosenInverters.length > compareMax - 1) {
                alert(strings.comparePage.tooManyItemsInverters);
                return;
            }
            addChosenInverter(event.id);
        }
    };

    const handleClickCompare = (compareAll) => {
        if (activeTab === "1") {
            if (compareAll) {
                setChosenDevices(props.devices);
                setNotChosenDevices([]);
            }
        }
        if (activeTab === "2") {
            if (compareAll) {
                setChosenPanels(props.panels);
                setNotChosenPanels([]);
            }
        }
        setStep(2);
    };

    const panelHeader = (header) => (
        <React.Fragment>
            <Row
                style={{ cursor: "default", fontSize: "1.5rem", fontWeight: "500" }}
                onClick={(event) => event.stopPropagation()}
            >
                <Typography.Text>{header}</Typography.Text>
            </Row>
        </React.Fragment>
    );

    const onClickStartOver = () => {
        setStep(1);
        setFilteredDevices(props.devices);
        setFilteredPanels(props.panels);
        setFilteredInverters(props.inverters);
        setChosenDevices([]);
        setChosenPanels([]);
        setChosenInverters([]);
    };

    const clearFilters = () => {
        setFilteredDevices(props.devices);
        setFilteredPanels(props.panels);
        setFilteredInverters(props.inverters);
    };

    const removeChosenDevice = (deviceId) => {
        const newChosenDevices = chosenDevices.filter((cd) => cd.deviceId !== deviceId);
        setChosenDevices(newChosenDevices);
        const notChosen = props.devices.filter((d) => newChosenDevices.indexOf(d) < 0);
        setNotChosenDevices(notChosen);
    };

    const addChosenDevice = (deviceId) => {
        const newDevice = props.devices.find((d) => d.deviceId === deviceId);
        const newChosenDevices = chosenDevices.concat(newDevice);
        setChosenDevices(newChosenDevices);
        const notChosen = props.devices.filter((d) => newChosenDevices.indexOf(d) < 0);
        setNotChosenDevices(notChosen);
    };

    const removeChosenPanel = (panelId) => {
        const newChosenPanels = chosenPanels.filter((p) => p.id !== panelId);
        setChosenPanels(newChosenPanels);
        const notChosen = props.panels.filter((p) => newChosenPanels.indexOf(p) < 0);
        setNotChosenPanels(notChosen);
    };

    const addChosenPanel = (panelId) => {
        const newPanel = props.panels.find((p) => p.id === panelId);
        const newChosenPanels = chosenPanels.concat(newPanel);
        setChosenPanels(newChosenPanels);
        const notChosen = props.panels.filter((p) => newChosenPanels.indexOf(p) < 0);
        setNotChosenPanels(notChosen);
    };

    const removeChosenInverter = (inverterId) => {
        const newChosenInverters = chosenInverters.filter((p) => p.id !== inverterId);
        setChosenInverters(newChosenInverters);
        const notChosen = props.inverters.filter((p) => newChosenInverters.indexOf(p) < 0);
        setNotChosenInverters(notChosen);
    };

    const addChosenInverter = (inverterId) => {
        const newInverter = props.inverters.find((p) => p.id === inverterId);
        const newChosenInverters = chosenInverters.concat(newInverter);
        setChosenInverters(newChosenInverters);
        const notChosen = props.inverters.filter((p) => newChosenInverters.indexOf(p) < 0);
        setNotChosenInverters(notChosen);
    };

    const handleSetFilteredDevices = (filteredDevices) => {
        setChosenDevices(chosenDevices.filter((cd) => filteredDevices.includes(cd)));
        const sortedDevices = filteredDevices.sort((a, b) =>
            a.properties.find((p) => p.key === "display name").value >
            b.properties.find((p) => p.key === "display name").value
                ? 1
                : b.properties.find((p) => p.key === "display name").value >
                  a.properties.find((p) => p.key === "display name").value
                ? -1
                : 0
        );
        setFilteredDevices(sortedDevices);
    };

    const handleSetFilteredPanels = (filteredPanels) => {
        setChosenPanels(chosenPanels.filter((p) => filteredPanels.includes(p)));
        const sortedPanels = filteredPanels.sort((a, b) =>
            a.properties.find((p) => p.key === "model").value > b.properties.find((p) => p.key === "model").value
                ? 1
                : b.properties.find((p) => p.key === "model").value > a.properties.find((p) => p.key === "model").value
                ? -1
                : 0
        );
        setFilteredPanels(sortedPanels);
    };

    const handleSetFilteredInverters = (filteredInverters) => {
        setChosenInverters(chosenInverters.filter((p) => filteredInverters.includes(p)));
        const sortedInverters = filteredInverters.sort((a, b) =>
            a.properties.find((p) => p.key === "model").value > b.properties.find((p) => p.key === "model").value
                ? 1
                : b.properties.find((p) => p.key === "model").value > a.properties.find((p) => p.key === "model").value
                ? -1
                : 0
        );
        setFilteredInverters(sortedInverters);
    };

    const getDeviceIdentifiers = (devices) => {
        if (devices === undefined || devices.length < 1) return "";
        let isEM = [
            devices.find((v) => v.identity.includes("_EM1")) && "EnergyMeter1",
            devices.find((v) => v.identity.includes("_EM2")) && "EnergyMeter2",
        ];
        isEM = isEM.filter((v) => v).map((v) => "&var-EnergyMeter=" + v);
        const identifiers = devices.map((v) => "&var-plants=" + v.identity.replace(/_EM1|_EM2/, ""));
        return identifiers.concat(isEM).join("");
    };

    function getSideMenuPart1() {
        var filterOption;
        if (activeTab === "1") {
            filterOption = (
                <FilterOptions
                    key="device"
                    type="device"
                    cleanFunc={clearFilters}
                    filterPanels={false}
                    items={props.devices}
                    options={[
                        {
                            name: strings.comparePage.compareStepCompare.filters.city,
                            value: "city",
                            type: "multiple",
                            dataType: "string",
                            shouldHide: false,
                        },
                        {
                            name: strings.comparePage.compareStepCompare.filters.yearlyProduction,
                            value: "total yield during last year (kWh)",
                            type: "range",
                            sliderInc: 1000,
                            dataType: "string",
                        },
                        {
                            name: "Azimut",
                            value: "azimuth",
                            type: "range",
                            dataType: "array",
                        },
                        {
                            name: "Takvinkel",
                            value: "roof angle",
                            type: "range",
                            dataType: "array",
                        },
                        {
                            name: "Taktyp",
                            value: "roof type",
                            type: "multiple",
                            dataType: "array",
                        },
                        {
                            name: strings.comparePage.compareStepCompare.filters.filterSolarPanelType,
                            value: "solar cell type",
                            type: "multiple",
                            dataType: "array",
                        },
                        {
                            name: strings.comparePage.compareStepCompare.filters.filterSolarPanelTech,
                            value: "solar cell technology",
                            type: "multiple",
                            dataType: "array",
                        },
                        {
                            name: strings.comparePage.compareStepCompare.filters.filterSolarPanelBrand,
                            value: "solar panel brand",
                            type: "multiple",
                            dataType: "array",
                        },
                    ]}
                    onChange={handleSetFilteredDevices}
                />
            );
        } else if (activeTab === "2") {
            filterOption = (
                <FilterOptions
                    key="panel"
                    type="panel"
                    cleanFunc={clearFilters}
                    filterPanels={true}
                    items={props.panels}
                    options={[
                        {
                            name: strings.comparePage.compareStepCompare.sunPanelsSpecificationPanel.cellType,
                            value: "cellType",
                            type: "multiple",
                            dataType: "string",
                        },
                        {
                            name: strings.comparePage.compareStepCompare.sunPanelsSpecificationPanel.technology,
                            value: "technology",
                            type: "multiple",
                            dataType: "string",
                        },
                        {
                            name: strings.comparePage.compareStepCompare.sunPanelsSpecificationPanel.solarPanelBrand,
                            value: "brand",
                            type: "multiple",
                            dataType: "string",
                        },
                    ]}
                    onChange={handleSetFilteredPanels}
                />
            );
        } else if (activeTab === "3") {
            filterOption = (
                <FilterOptions
                    key="inverter"
                    type="inverter"
                    cleanFunc={clearFilters}
                    filterPanels={true}
                    items={props.inverters}
                    options={[
                        {
                            name: strings.comparePage.compareStepCompare.InverterSpecificationPanel.inverterBrand,
                            value: "brand",
                            type: "multiple",
                            dataType: "string",
                        },
                        {
                            name: strings.comparePage.compareStepCompare.InverterSpecificationPanel.inverterPower,
                            value: "rated power [w]",
                            type: "range",
                            dataType: "string",
                        },
                    ]}
                    onChange={handleSetFilteredInverters}
                />
            );
        }
        return (
            <Row className="p-1" style={{ paddingTop: "0" }}>
                {filterOption}
            </Row>
        );
    }

    function getSideMenuPart2() {
        let sideMenu;
        if (activeTab === "1") {
            sideMenu = (
                <Row>
                    {chosenDevices.map((d) => (
                        <CheckboxButton
                            key={getKeyValue(d, "display name")}
                            text={getKeyValue(d, "display name")}
                            value={d.deviceId}
                            checked={true}
                            borderColor="#007FFF"
                            static={true}
                            onChange={(value, checked) =>
                                handleChosenDevicesChange({
                                    id: value,
                                    checked: checked,
                                })
                            }
                        />
                    ))}
                    <span className="grey-color h5" style={{ marginTop: "30px" }}>
                        {strings.comparePage.notChosen}
                    </span>
                    {notChosenDevices.map((d) => (
                        <CheckboxButton
                            key={getKeyValue(d, "display name")}
                            text={getKeyValue(d, "display name")}
                            value={d.deviceId}
                            checked={false}
                            borderColor="#DDDDDD"
                            static={true}
                            onChange={(value, checked) =>
                                handleChosenDevicesChange({
                                    id: value,
                                    checked: checked,
                                })
                            }
                        />
                    ))}
                </Row>
            );
        } else if (activeTab === "2") {
            sideMenu = (
                <Row>
                    {chosenPanels.map((p) => (
                        <CheckboxButton
                            key={getKeyValue(p, "model")}
                            text={getKeyValue(p, "model")}
                            value={p.id}
                            checked={true}
                            borderColor="#007FFF"
                            static={true}
                            onChange={(value, checked) =>
                                handleChosenPanelsChange({
                                    id: value,
                                    checked: checked,
                                })
                            }
                        />
                    ))}
                    <span className="grey-color h5" style={{ marginTop: "30px" }}>
                        {strings.comparePage.notChosen}
                    </span>
                    {notChosenPanels.map((p) => (
                        <CheckboxButton
                            key={getKeyValue(p, "model")}
                            text={getKeyValue(p, "model")}
                            value={p.id}
                            checked={false}
                            borderColor="#DDDDDD"
                            static={true}
                            onChange={(value, checked) =>
                                handleChosenPanelsChange({
                                    id: value,
                                    checked: checked,
                                })
                            }
                        />
                    ))}
                </Row>
            );
        } else if (activeTab === "3") {
            sideMenu = (
                <Row>
                    {chosenInverters.map((p) => (
                        <CheckboxButton
                            key={getKeyValue(p, "model")}
                            text={getKeyValue(p, "model")}
                            value={p.id}
                            checked={true}
                            borderColor="#007FFF"
                            static={true}
                            onChange={(value, checked) =>
                                handleChosenInvertersChange({
                                    id: value,
                                    checked: checked,
                                })
                            }
                        />
                    ))}
                    <span className="grey-color h5" style={{ marginTop: "30px" }}>
                        {strings.comparePage.notChosen}
                    </span>
                    {notChosenInverters.map((p) => (
                        <CheckboxButton
                            key={getKeyValue(p, "model")}
                            text={getKeyValue(p, "model")}
                            value={p.id}
                            checked={false}
                            borderColor="#DDDDDD"
                            static={true}
                            onChange={(value, checked) =>
                                handleChosenInvertersChange({
                                    id: value,
                                    checked: checked,
                                })
                            }
                        />
                    ))}
                </Row>
            );
        }
        return (
            <Row className="p-1" style={{ paddingTop: "0" }}>
                <span className="grey-color h5">{strings.comparePage.chosen}</span>
                {sideMenu}
            </Row>
        );
    }

    const getSelectedBar = () => {
        let selected;
        if (activeTab === "1") selected = chosenDevices;
        if (activeTab === "2") selected = chosenPanels;
        if (activeTab === "3") selected = chosenInverters;
        if (selected.length < 1) return;
        const getClearButton = () => {
            return (
                <div>
                    <Button
                        className="button-primary width-100"
                        shape="round"
                        onClick={() => handleClickCompare(false)}
                        style={{
                            width: "max-content",
                            boxShadow: "rgba(0, 0, 0, 0.15) 0 2px 13px 0px",
                            margin: "4px 0.5em",
                        }}
                        disabled={
                            (activeTab === "1" &&
                                (chosenDevices.length < compareMin || chosenDevices.length > compareMax)) ||
                            (activeTab === "2" &&
                                (chosenPanels.length < compareMin || chosenPanels.length > compareMax)) ||
                            (activeTab === "3" &&
                                (chosenInverters.length < compareMin || chosenInverters.length > compareMax))
                        }
                    >
                        {strings.comparePage.buttonCompareChosen}
                    </Button>
                    <Button
                        className="button-secondary width-100"
                        shape="round"
                        onClick={() => {
                            if (activeTab === "1") setChosenDevices([]);
                            if (activeTab === "2") setChosenPanels([]);
                            if (activeTab === "3") setChosenInverters([]);
                        }}
                        style={{
                            width: "max-content",
                            boxShadow: "rgba(0, 0, 0, 0.15) 0 2px 13px 0px",
                            margin: "4px 0.5em",
                        }}
                    >
                        Rensa valda
                    </Button>
                </div>
            );
        };
        return (
            <div
                style={{
                    position: "sticky",
                    width: "100%",
                    top: "calc(64px + 1em)",
                    zIndex: "10",
                    backgroundColor: "rgba(255, 255, 255, 0.9)",
                    backdropFilter: "blur(6px)",
                    WebkitBackdropFilter: "blur(6px)",
                    borderRadius: "var(--borderrad)",
                    padding: "1em 0",
                    boxShadow: "rgba(0, 0, 0, 0.15) 0 2px 13px 0px",
                }}
            >
                <Collapse ghost={true} bordered={false} defaultActiveKey={"1"} expandIconPosition="end">
                    <Panel
                        extra={getClearButton()}
                        key="1"
                        header="Valda"
                        style={{
                            color: "white",
                        }}
                    >
                        <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "space-evenly" }}>
                            {selected.map((v) => {
                                return (
                                    <div
                                        key={v.displayName}
                                        style={{
                                            width: "120px",
                                            backgroundColor: "white",
                                            padding: "0.5em",
                                            margin: "0.5em",
                                            textAlign: "center",
                                            borderRadius: "var(--borderrad)",
                                            boxShadow: "rgba(0, 0, 0, 0.15) 0 2px 13px 0px",
                                            border: "2px solid #1890ff",
                                            cursor: "pointer",
                                            position: "relative",
                                        }}
                                        onClick={() => {
                                            if (activeTab === "1")
                                                handleChosenDevicesChange({ id: v.deviceId, checked: false });
                                            if (activeTab === "2")
                                                handleChosenPanelsChange({ id: v.id, checked: false });
                                            if (activeTab === "3")
                                                handleChosenInvertersChange({ id: v.id, checked: false });
                                        }}
                                    >
                                        <span
                                            style={{
                                                position: "absolute",
                                                top: "-10px",
                                                right: "-10px",
                                                backgroundColor: "white",
                                                boxShadow: "rgba(0, 0, 0, 0.15) 0 2px 13px 0px",
                                                width: "20px",
                                                height: "20px",
                                                borderRadius: "10px",
                                                textAlign: "center",
                                                lineHeight: "20px",
                                            }}
                                        >
                                            ⨯
                                        </span>
                                        <h1 style={{ color: "black", margin: "0" }}>{getKeyValue(v, "display name") === "-" ? v.displayName : getKeyValue(v, "display name")}</h1>
                                    </div>
                                );
                            })}
                        </div>
                    </Panel>
                </Collapse>
            </div>
        );
    };

    return (
        <div style={{ marginBottom: "4em" }}>
                <div className="home-text">
            <h1 className="h1 white-color">{strings.comparePage.title}</h1>
            {step === 1 && (
                <h3 className="white-color">
                 
                    <p>
                        <HtmlComponent value={`${strings.comparePage.pageDescription.join(" ")}`} />
                    </p>
                    

                </h3>
            )}
            </div>
            <Row>
                <h2 className="h2 white-color" style={{ marginBottom: "0.5em" }}>
                    {step === 1 ? strings.comparePage.stepChoose : strings.comparePage.stepCompare}
                </h2>
            </Row>
            {step === 1 ? (
                <Row gutter={16}>
                    <Col span={6}>
                        <div className="bg-white height-100" style={{ borderRadius: "var(--borderrad)" }}>
                            <Row className="p-1">
                                <Button
                                    className="button-primary width-100"
                                    shape="round"
                                    onClick={() => handleClickCompare(false)}
                                    style={{ marginBottom: "10px" }}
                                    disabled={
                                        (activeTab === "1" &&
                                            (chosenDevices.length < compareMin || chosenDevices.length > compareMax)) ||
                                        (activeTab === "2" &&
                                            (chosenPanels.length < compareMin || chosenPanels.length > compareMax)) ||
                                        (activeTab === "3" &&
                                            (chosenInverters.length < compareMin ||
                                                chosenInverters.length > compareMax))
                                    }
                                >
                                    {strings.comparePage.buttonCompareChosen}
                                </Button>
                            </Row>
                            {isMobile ? (
                                <Collapse ghost>
                                    <Panel
                                        style={{ paddingBottom: "1em" }}
                                        header={strings.comparePage.mobileAccordionFilteringAlternatives}
                                        key="1"
                                    >
                                        {getSideMenuPart1()}
                                    </Panel>
                                </Collapse>
                            ) : (
                                getSideMenuPart1()
                            )}
                        </div>
                    </Col>
                    <Col span={18}>
                        <div
                            className="bg-white height-100 p-1"
                            style={{ borderRadius: "var(--borderrad)", marginTop: isMobile ? "1em" : "0" }}
                        >
                            {getSelectedBar()}
                            <Row className="compare-tabs">
                                <Tabs
                                    activeKey={activeTab}
                                    tabPosition="top"
                                    className="width-100"
                                    style={{ padding: "0.5em" }}
                                    onChange={(t) => setActiveTab(t)}
                                >
                                    <TabPane tab={strings.comparePage.tabFacilities} key="1">
                                        <Row
                                            justify={
                                                props.isLoading || filteredDevices === undefined ? "center" : "start"
                                            }
                                            gutter={16}
                                            style={{ padding: "0.5em" }}
                                        >
                                            {props.isLoading || filteredDevices === undefined ? (
                                                <Spin size="large" style={{ padding: "5em" }} />
                                            ) : (
                                                filteredDevices.map((d, i) => {
                                                    let img = d.properties.filter(
                                                        (val) => val["key"] === "image urls"
                                                    )[0];
                                                    let effect = d.properties.filter(
                                                        (val) => val["key"] === "installed peak power [kwp]"
                                                    )[0];
                                                    return (
                                                        <CompareFacilityCard
                                                            key={i}
                                                            id={d.deviceId}
                                                            name={getKeyValue(d, "display name")}
                                                            image={img?.value}
                                                            effect={effect?.value}
                                                            chosen={
                                                                chosenDevices.find(
                                                                    (cd) => cd.deviceId === d.deviceId
                                                                ) !== undefined
                                                            }
                                                            city={getKeyValue(d, "city")}
                                                            onChange={handleChosenDevicesChange}
                                                        />
                                                    );
                                                })
                                            )}
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={strings.comparePage.tabSunpanels} key="2">
                                        <Row
                                            justify={
                                                props.isLoading || filteredPanels === undefined ? "center" : "start"
                                            }
                                            gutter={16}
                                            style={{ padding: "0.5em" }}
                                        >
                                            {props.isLoading || filteredPanels === undefined ? (
                                                <Spin size="large" style={{ padding: "5em" }} />
                                            ) : filteredPanels.length === 0 ? (
                                                <span
                                                    style={{
                                                        textAlign: "center",
                                                        width: "100%",
                                                        color: "rgb(50,50,50)",
                                                    }}
                                                >
                                                    {strings.comparePage.noItems}
                                                </span>
                                            ) : (
                                                filteredPanels.map((p, i) => {
                                                    const getProperty = (key) => {
                                                        return p.properties.find((val) => val.key === key).value;
                                                    };
                                                    return (
                                                        <ComparePanelCard
                                                            key={i}
                                                            id={p.id}
                                                            image={p.image}
                                                            cellType={getProperty("cellType")}
                                                            tech={getProperty("technology")}
                                                            smallImage={p.smallImage}
                                                            model={getKeyValue(p, "model")}
                                                            chosen={
                                                                chosenPanels.find((pa) => pa.id === p.id) !== undefined
                                                            }
                                                            brand={getKeyValue(p, "brand")}
                                                            onChange={handleChosenPanelsChange}
                                                        />
                                                    );
                                                })
                                            )}
                                        </Row>
                                    </TabPane>
                                    <TabPane tab={strings.comparePage.tabInverters} key="3">
                                        <Row
                                            justify={
                                                props.isLoading || filteredInverters === undefined ? "center" : "start"
                                            }
                                            gutter={16}
                                            style={{ padding: "0.5em" }}
                                        >
                                            {props.isLoading || filteredInverters === undefined ? (
                                                <Spin size="large" style={{ padding: "5em" }} />
                                            ) : filteredInverters.length === 0 ? (
                                                <span
                                                    style={{
                                                        textAlign: "center",
                                                        width: "100%",
                                                        color: "rgb(50,50,50)",
                                                    }}
                                                >
                                                    {strings.comparePage.noItems}
                                                </span>
                                            ) : (
                                                filteredInverters.map((p, i) => {
                                                    return (
                                                        <CompareInverterCard
                                                            key={i}
                                                            id={p.id}
                                                            image={p.image}
                                                            smallImage={p.smallImage}
                                                            model={getKeyValue(p, "model")}
                                                            chosen={
                                                                chosenInverters.find((pa) => pa.id === p.id) !==
                                                                undefined
                                                            }
                                                            brand={getKeyValue(p, "brand")}
                                                            onChange={handleChosenInvertersChange}
                                                        />
                                                    );
                                                })
                                            )}
                                        </Row>
                                    </TabPane>
                                </Tabs>
                            </Row>
                        </div>
                    </Col>
                </Row>
            ) : (
                <div
                    style={{
                        display: "grid",
                        gridTemplate: "left right",
                        gridTemplateColumns: isMobile ? "100%" : "25% 75%",
                        justifyContent: "center",
                    }}
                >
                    <div
                        className="bg-white"
                        style={{ borderRadius: "var(--borderrad)", margin: "0 6px", height: "max-content" }}
                    >
                        <div style={{ padding: "1em 1em 0 1em" }}>
                            <Button
                                className="button-secondary width-100"
                                shape="round"
                                style={{ marginBottom: "20px" }}
                                onClick={() => onClickStartOver()}
                            >
                                {strings.comparePage.buttonCompareStartOver}
                            </Button>
                        </div>
                        {isMobile ? (
                            <Collapse ghost>
                                <Panel
                                    style={{ paddingBottom: "1em" }}
                                    header={strings.comparePage.mobileAccordionChosenAndNotChosenValues}
                                    key="1"
                                >
                                    {getSideMenuPart2()}
                                </Panel>
                            </Collapse>
                        ) : (
                            getSideMenuPart2()
                        )}
                    </div>
                    <div
                        className="height-100"
                        style={{ marginTop: isMobile ? "1em" : "0", margin: isMobile ? " 16px 6px 0 6px" : "0 8px" }}
                    >
                        <Row>
                            <Collapse
                                ghost
                                className="width-100"
                                expandIcon={({ isActive }) => (
                                    <Button type="link" style={{ fontWeight: "600", cursor: "pointer" }}>
                                        {isActive ? "Stäng detaljer" : "Visa detaljer"}
                                    </Button>
                                )}
                                expandIconPosition="end"
                                defaultActiveKey={["1", "2"]}
                            >
                                <Panel
                                    header={panelHeader(
                                        strings.comparePage.compareStepCompare.specificationPanel.header
                                    )}
                                    key="2"
                                    className="panel-style"
                                    onClick={(event) => event.stopPropagation()}
                                >
                                    {activeTab === "1" ? (
                                        <CompareDeviceSpecification devices={chosenDevices} />
                                    ) : activeTab === "2" ? (
                                        <ComparePanelSpecification panels={chosenPanels} />
                                    ) : (
                                        <CompareInverterSpecification inverters={chosenInverters} />
                                    )}
                                </Panel>
                            </Collapse>
                            {activeTab === "1" && (
                                <Collapse
                                    ghost
                                    className="width-100"
                                    expandIcon={({ isActive }) => (
                                        <Button type="link" style={{ fontWeight: "600", cursor: "pointer" }}>
                                            {isActive ? "Stäng detaljer" : "Visa detaljer"}
                                        </Button>
                                    )}
                                    expandIconPosition="end"
                                    defaultActiveKey={["1", "2"]}
                                >
                                    <Panel
                                        key="1"
                                        header={panelHeader(
                                            strings.comparePage.compareStepCompare.specificationPanelGrafana.header
                                        )}
                                        className="panel-style"
                                        onClick={(event) => event.stopPropagation()}
                                    >
                                        {chosenDevices.length < 1 ? (
                                            <span style={{ textAlign: "center", display: "block" }}>
                                                Inga valda anläggningar
                                            </span>
                                        ) : (
                                            <div style={{ height: "100%" }}>
                                                <iframe
                                                    width="100%"
                                                    height="600px"
                                                    title="Grafana Effekt"
                                                    src={
                                                        "https://view.stuns.i0t.se/grafana/d-solo/OEgZIAfZz/energi?orgId=3&theme=light&panelId=5" +
                                                        getDeviceIdentifiers(chosenDevices)
                                                    }
                                                    frameBorder="0"
                                                ></iframe>
                                            </div>
                                        )}
                                    </Panel>
                                </Collapse>
                            )}
                        </Row>
                    </div>
                </div>
            )}
        </div>
    );
}
export default Compare;
