import React from "react";
import { Spin } from "antd";
import ReactModal from "react-modal";
import ModalContent from "./ModalContent";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import "./MultiFacilityModal.scss";

ReactModal.setAppElement("#root");
export default class MultiFacilityModal extends React.Component {
    state = {
        currentVisibleIndex: this.props.index,
        reset: false,
    };

    componentDidUpdate(prevProps, _prevState) {
        if (this.props.index !== prevProps.index) {
            this.setState({ currentVisibleIndex: this.props.index });
        }
    }

    hideModal = () => {
        this.setState({
            reset: !this.state.reset,
            currentVisibleIndex: this.props.index,
        });
        this.props.handleCancel();
    };

    render() {
        const getMargins = () => {
            let size = "0";
            if (this.props.isFullscreen === false) {
                size = this.props.size.isMobile ? "0.5em" : "2em";
            }
            return size;
        };
        return (
            <ReactModal
                className="facility-react-modal"
                isOpen={this.props.visible}
                onRequestClose={this.hideModal}
                onAfterOpen={() => (document.body.style.overflow = "hidden")}
                onAfterClose={() => (document.body.style.overflow = "unset")}
                style={{
                    overlay: {
                        zIndex: 1000,
                        backgroundColor: "rgba(0, 0, 0, 0.45)",
                        position: "fixed",
                        height: "100vh",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        overflow: "auto",
                    },
                    content: {
                        outline: "none",
                        border: "none",
                        backgroundColor: "white",
                        padding: "0",
                        margin: "auto",
                        width: (() => {
                            let w = "100%";
                            if (this.props.isFullscreen === false) {
                                w = this.props.isMobile ? "calc(100% - 1em)" : "calc(100% - 4em)";
                            }
                            return w;
                        })(),
                        maxWidth: this.props.isFullscreen ? "initial" : "1200px",
                        height: this.props.isFullscreen ? "100%" : "max-content",
                        position: "relative",
                        top: getMargins(),
                        left: "0",
                        right: "0",
                        bottom: getMargins(),
                        overflow: "auto",
                        WebkitOverflowScrolling: "touch",
                        borderRadius: this.props.isFullscreen ? "0" : "var(--borderrad)",
                    },
                }}
            >
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span className="close-button" onClick={this.hideModal}>
                        ✕
                    </span>
                    {this.props.isLoading || !this.props.devices ? (
                        <div
                            style={{
                                height: "80vh",
                                display: "grid",
                                placeItems: "center",
                            }}
                        >
                            <Spin size="large" />
                        </div>
                    ) : this.state.currentVisibleIndex < 0 ? (
                        <div style={{ textAlign: "center", fontSize: "1.4rem", marginBottom: "2em" }}>
                            Hittade ingen anläggning för denna url...
                        </div>
                    ) : (
                        <Carousel
                            dynamicHeight={false}
                            emulateTouch={true}
                            swipeScrollTolerance={200}
                            swipeable={false}
                            showArrows={true}
                            showStatus={false}
                            showThumbs={false}
                            showIndicators={false}
                            infiniteLoop={false}
                            autoPlay={false}
                            selectedItem={this.props.index}
                            key={this.props.index + this.state.reset}
                            onChange={(i) => {
                                this.props.onChange(this.props.devices[i].identity);
                                this.setState({ currentVisibleIndex: i });
                            }}
                            renderArrowNext={(clickhandler, hasNext, _label) => (
                                <button
                                    className={"slider-button right " + (!hasNext ? "last" : "")}
                                    onClick={clickhandler}
                                >
                                    <div className="button right"></div>
                                </button>
                            )}
                            renderArrowPrev={(clickhandler, hasNext, _label) => (
                                <button
                                    className={"slider-button left " + (!hasNext ? "last" : "")}
                                    onClick={clickhandler}
                                >
                                    <div className="button left"></div>
                                </button>
                            )}
                        >
                            {this.props.devices.map((d, i) => {
                                return (
                                    <ModalContent
                                        key={i + d.deviceId}
                                        visible={i === this.state.currentVisibleIndex}
                                        id={d.deviceId}
                                        handleCancel={this.props.handleCancel}
                                        device={d}
                                        size={this.props.size}
                                    />
                                );
                            })}
                        </Carousel>
                    )}
                </div>
            </ReactModal>
        );
    }
}
