import React, { useEffect, useState, useCallback } from "react";
import { Button, Col, Row, Tabs } from "antd";
import "react-gallery-carousel/dist/index.css";
import GalleryCarousel from "react-gallery-carousel";
import strings from "../../strings/strings";
import getKeyValue from "../../helpers/getKeyValue.js";
import getUniqueKeyValuesFromSubDevices from "../../helpers/getUniqueKeyValuesFromSubDevices.js";
import SpecificationTable from "../SpecificationTable/SpecificationTable";
import HtmlComponent from "../../components/HtmlComponent.js";
import { getEfficiencyIndexData } from "../../services/apiService";
import DateConverter from "../../components/DateConverter/DateConverter.js";
import LazyLoadComponent from "../LazyLoadComponent/LazyLoadComponent";

import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts";

const { TabPane } = Tabs;



function ModalContent(props) {
    // To show the Index tab
    const showIndex = false;

    const [efficiencyIndexData, setEfficiencyIndexData] = useState({
        deviceIds: new Set(),
        values: [],
    });
    const [hasEfficiencyIndexData, setHasEfficiencyIndexData] = useState(false);
    const [images, setImages] = useState([]);
    const [allGrafanaCharts, setAllGrafanaCharts] = useState([]);
    const [deviceData, setDeviceData] = useState([]);
    const [deviceSpec, setDeviceSpec] = useState([]);
    const [isVisible, setIsVisible] = useState(false);

    const createDeviceData = useCallback((devices) => {
        return devices.map((device) => ({
            device: device,
            name: getKeyValue(device, "display name"),
            image: getKeyValue(device, "image urls").split(";")[0],
        }));
    }, []);

    const createDeviceSpec = useCallback((devices) => {
        if (devices.length < 1) return [];
        const stringsPart = strings.comparePage.compareStepCompare.specificationPanel;
        return [
            {
                key: "Produktion",
                value: [
                    {
                        key: stringsPart.lastweekTotalYieldProduction,
                        value: devices.map((device) =>
                            getKeyValue(device, "total yield during last week (kWh)", undefined, true)
                        ),
                    },
                    {
                        key: stringsPart.lastmonthTotalYieldProduction,
                        value: devices.map((device) =>
                            getKeyValue(device, "total yield during last month (kWh)", undefined, true)
                        ),
                    },
                    {
                        key: stringsPart.lastyearTotalYieldProduction,
                        value: devices.map((device) =>
                            getKeyValue(device, "total yield during last year (kWh)", undefined, true)
                        ),
                    },
                    {
                        key: stringsPart.totalYieldProduction,
                        value: devices.map((device) => getKeyValue(device, "total yield (kWh)", undefined, true)),
                    },
                    {
                        key: stringsPart.investment,
                        value: devices.map((device) => getKeyValue(device, "investment [sek/kwp]")),
                    },
                    {
                        key: stringsPart.installer,
                        value: devices.map((device) => getKeyValue(device, "installer")),
                    },
                ],
            },
            {
                key: "Anläggning",
                value: [
                    {
                        key: "Monteringssystem",
                        value: devices.map((device) => {
                            return getUniqueKeyValuesFromSubDevices(device.subDevices, "mounting system");
                        }),
                    },
                    {
                        key: "Monteringsvinkel [°]",
                        value: devices.map((device) => {
                            return getUniqueKeyValuesFromSubDevices(device.subDevices, "mounting angle") + "°";
                        }),
                    },
                    {
                        key: "Takvinkel [°]",
                        value: devices.map((device) => {
                            let angle = getKeyValue(device, "roof angle");
                            if (typeof angle === typeof []) {
                                angle = angle.map((v) => v + "°").join(", ");
                            } else {
                                angle += "°";
                            }
                            return angle;
                        }),
                    },
                    {
                        key: "Taktyp",
                        value: devices.map((device) => {
                            let roof_type = getKeyValue(device, "roof type");
                            if (typeof roof_type === typeof []) roof_type = roof_type.join(", ");
                            return roof_type;
                        }),
                    },
                    {
                        key: "Azimut [°]",
                        value: devices.map((device) => {
                            return getUniqueKeyValuesFromSubDevices(device.subDevices, "azimuth") + "°";
                        }),
                    },

                    {
                        key: "Modultyp",
                        value: devices.map((device) =>
                            getUniqueKeyValuesFromSubDevices(device.subDevices, "solar module type")
                        ),
                    },
                    {
                        key: "Verkningsgrad [%]",
                        value: devices.map((device) => {
                            let val = getUniqueKeyValuesFromSubDevices(device.subDevices, "efficiency [%]");
                            val = val.split(",");
                            val = val.map((v) => (parseFloat(v.trim()) * 100).toFixed(1).toString() + "%");
                            return val.join(", ");
                        }),
                    },
                ],
            },
            {
                key: "Modellbeskrivning",
                value: [
                    {
                        key: stringsPart.technology,
                        value: devices.map((device) =>
                            getUniqueKeyValuesFromSubDevices(
                                device.subDevices,
                                "solar cell type",
                                "solar cell technology"
                            )
                        ),
                    },
                    {
                        key: stringsPart.effect,
                        value: devices.map((device) => getKeyValue(device, "installed peak power [kwp]")),
                    },
                    {
                        key: stringsPart.solarPanelBrand,
                        value: devices.map((device) => getKeyValue(device, "solar panel brand")),
                    },
                    {
                        key: stringsPart.inverterBrand,
                        value: devices.map((device) => getKeyValue(device, "inverter brand")),
                    },
                    {
                        key: stringsPart.solarPanelModel,
                        value: devices.map((device) =>
                            getUniqueKeyValuesFromSubDevices(device.subDevices, "solar panel model")
                        ),
                    },
                ],
            },
        ];
    }, []);

    useEffect(() => {
        if (!props.visible && isVisible) setIsVisible(false);
        if (!props.visible || typeof props.id !== typeof 0) return;
        if (showIndex) {
            getEfficiencyIndexData([props.id]).then((res) => {
                let data = { deviceIds: new Set(), values: [] };
                let obj = res.data;
                for (const date of obj.dates) {
                    const dateObject = { date: date };
                    // Each Device
                    for (const deviceData of obj.deviceParameterDataSets) {
                        if (!hasEfficiencyIndexData) setHasEfficiencyIndexData(true);
                        const id = deviceData.device.deviceId;
                        const value = deviceData.dailyValues.find((v) => v.date === date)?.value ?? 0;
                        dateObject[id] = value;
                        data.deviceIds.add(id);
                    }
                    // Index
                    const value = obj.indexParameterDataSet.dailyValues.find((v) => v.date === date)?.value ?? 0;
                    dateObject["index"] = value;

                    data.values.push(dateObject);
                }
                setEfficiencyIndexData(data);
            });
        }

        if (!props.device) return;
        if (props.device.properties.find((p) => p.key === "image urls")) {
            const images = props.device.properties.find((p) => p.key === "image urls").value;
            const imageArray = images === null || images === undefined || images === "" ? [] : images.split(";");
            setImages(imageArray);
        }
        if (props.device.properties.find((p) => p.key === "grafana charts")) {
            const grafanaCharts = props.device.properties.find((p) => p.key === "grafana charts").value;
            const grafanaChartsArray =
                grafanaCharts === null || grafanaCharts === undefined || grafanaCharts === ""
                    ? []
                    : grafanaCharts.split("|");
            let allCharts = [];
            for (const chart of grafanaChartsArray) {
                const c = chart.split(";");
                let tabName = "";
                if (c[0] === "power") {
                    tabName = strings.aboutPage.tabs.tabPower;
                }
                if (c[0] === "energy") {
                    tabName = strings.aboutPage.tabs.tabEnergy;
                }
                if (c[0] === "irradiation") {
                    tabName = strings.aboutPage.tabs.tabIrradiation;
                }
                if (c[1]) {
                    const chartObject = {
                        key: c[0],
                        url: c[1],
                        title: tabName,
                    };
                    allCharts.push(chartObject);
                }
            }
            setAllGrafanaCharts(allCharts);
        }
        setDeviceSpec(createDeviceSpec([props.device]));
        setDeviceData(createDeviceData([props.device]));
        setIsVisible(true);
    }, [
        props.visible,
        props.id,
        setDeviceSpec,
        setDeviceData,
        createDeviceSpec,
        createDeviceData,
        setHasEfficiencyIndexData,
        hasEfficiencyIndexData,
        showIndex,
        props.data,
        props.device,
        props,
        isVisible,
    ]);

    if (!props.device) {
        return (
            <Row justify="center">
                <span style={{ fontSize: "1.5rem" }}>An Error has occurred</span>
            </Row>
        );
    }
    return (
        <div style={{ padding: "3em", backgroundColor: "white" }}>
            <div className="modal-body-content">
                <Row gutter={16} style={{ marginBottom: "4em" }}>
                    <Col className="modal-body-text" span={16}>
                        <h1 className="h1 black-color wordWrap">{getKeyValue(props.device, "display name")}</h1>
                        <h2 className="h3 black-color wordWrap">{getKeyValue(props.device, "city")}</h2>
                        <div style={{ textAlign: props.isTablet ? "center" : "start" }}>
                            <HtmlComponent value={getKeyValue(props.device, "description")} />
                        </div>
                        <p className="h3 black-color" style={{ textAlign: props.isTablet ? "center" : "start" }}>
                            {`Igång: ${DateConverter(getKeyValue(props.device, "activation date"))}`}
                        </p>
                        <p className="h3 black-color" style={{ textAlign: props.isTablet ? "center" : "start" }}>
                        <a target="_blank" rel="noreferrer" href= {(getKeyValue(
                                    props.device,
                                    "grafana charts",
                        ).replace("power;", "").replace("d-solo", "d").split("&theme")[0]
                        )}>
                       Alla Grafer
                        </a>    
                        </p>
                    </Col>
                    <Col className="modal-carousel-images" span={8} style={{ flex: "100" }}>
                        <h2 className="h3 black-color" style={{ marginTop: "1.5em" }}>
                            {strings.aboutPage.modal.images}
                        </h2>
                        <LazyLoadComponent style={{ height: "245px" }}>
                            {isVisible && images.length > 0 && (
                                <GalleryCarousel
                                    hasSizeButton={false}
                                    hasSizeButtonAtMax="topRight"
                                    shouldMinimizeOnSwipeDown={true}
                                    shouldSwipeOnMouse={true}
                                    autoPlayInterval={3000}
                                    hasMediaButton={false}
                                    isAutoPlaying={true}
                                    hasThumbnails={false}
                                    hasIndexBoard={false}
                                    hasDotButtons="bottom"
                                    shouldMaximizeOnClick={false}
                                    images={images.map((image) => ({ src: image }))}
                                    minIcon={
                                        <span
                                            style={{
                                                display: "block",
                                                color: "white",
                                                fontSize: "3em",
                                                height: "30px",
                                                width: "30px",
                                                lineHeight: "30px",
                                                margin: "10px",
                                            }}
                                        >
                                            &#10799;
                                        </span>
                                    }
                                />
                            )}
                        </LazyLoadComponent>
                    </Col>
                </Row>
                <div
                    style={{
                        paddingBottom: "4em",
                        display: "flex",
                        flexDirection: props.isMobile ? "column" : "row",
                        alignItems: "center",
                        justifyContent: "center",
                        textAlign: "center",
                        maxWidth: "1024px",
                        margin: "auto",
                    }}
                >
                    <div className="key-col">
                        <div>
                            <p className="h4 black-color mb-0">{strings.aboutPage.modal.technology}</p>
                            <p className="h2 black-color">
                                {getUniqueKeyValuesFromSubDevices(
                                    props.device.subDevices,
                                    "solar cell type",
                                    "solar cell technology"
                                )}
                            </p>
                        </div>
                        <div>
                            <p className="h4 black-color mb-0">{strings.aboutPage.modal.effect}</p>
                            <p className="h2 black-color">{getKeyValue(props.device, "installed peak power [kwp]")}</p>
                        </div>
                    </div>
                    <div className="key-col">
                        <div>
                            <p className="h4 black-color mb-0">{strings.aboutPage.modal.solarPanelBrand}</p>
                            <p className="h2 black-color">{getKeyValue(props.device, "solar panel brand")}</p>
                        </div>
                        <div>
                            <p className="h4 black-color mb-0">{strings.aboutPage.modal.inverterBrand}</p>
                            <p className="h2 black-color">{getKeyValue(props.device, "inverter brand")}</p>
                        </div>
                    </div>
                    <div className="key-col">
                        <div>
                            <p className="h4 black-color mb-0">{strings.aboutPage.modal.totalYieldProduction}</p>
                            <p className="h2 black-color">
                                {getKeyValue(props.device, "total yield (kWh)", "kWh", true)}
                            </p>
                        </div>
                        <div>
                            {(() => {
                                let title = strings.aboutPage.modal.lastyearTotalYieldProduction;
                                let val = getKeyValue(
                                    props.device,
                                    "total yield during last year (kWh)",
                                    undefined,
                                    true
                                );
                                if (val === undefined || val === "-") {
                                    title = strings.aboutPage.modal.lastmonthTotalYieldProduction;
                                    val = getKeyValue(
                                        props.device,
                                        "total yield during last month (kWh)",
                                        undefined,
                                        true
                                    );
                                }
                                return (
                                    <>
                                        <p className="h4 black-color mb-0">{title}</p>
                                        <p className="h2 black-color">{val}</p>
                                    </>
                                );
                            })()}
                        </div>
                    </div>
                </div>
               
                <div style={{ minHeight: "500px" }}>
                    {isVisible && (
                        <Row className="modal-tabs">
                            <Tabs type="card" tabPosition="bottom" className="width-100">
                                {(allGrafanaCharts !== undefined || allGrafanaCharts.length > 0) &&
                                    allGrafanaCharts.map((chart, i) => (
                                        <TabPane tab={chart.title} key={i}>
                                            <div>
                                                <LazyLoadComponent>
                                                    <iframe
                                                        style={{ margin: "0", width: "100%" }}
                                                        src={chart.url}
                                                        width="100%"
                                                        height="500"
                                                        frameBorder="0"
                                                        title={chart.title}
                                                    />
                                                </LazyLoadComponent>
                                            </div>
                                        </TabPane>
                                    ))}
                                {hasEfficiencyIndexData && showIndex && (
                                    <TabPane tab={strings.aboutPage.tabs.tabIndexData} key={allGrafanaCharts.length}>
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "center",
                                                alignItems: "center",
                                            }}
                                        >
                                            <LineChart
                                                width={400}
                                                height={400}
                                                data={efficiencyIndexData.values}
                                                startAngle={0}
                                                endAngle={0}
                                                angle={0}
                                            >
                                                {Array.from(efficiencyIndexData.deviceIds).map((v) => (
                                                    <Line key={v} type="monotone" dataKey={v} stroke="#8884d8" />
                                                ))}
                                                <Line type="monotone" dataKey="index" stroke="red" />
                                                <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                                                <XAxis dataKey="date" />
                                                <YAxis type="number" />
                                                <Tooltip />
                                            </LineChart>
                                        </div>
                                    </TabPane>
                                )}
                                <TabPane
                                    tab={strings.aboutPage.tabs.tabSpecification}
                                    key={allGrafanaCharts.length + 1}
                                >
                                    <div style={{ overflowX: "auto" }}>
                                        <div className="modal-specification-content">
                                            <SpecificationTable
                                                empty="Inga valda anläggningar..."
                                                data={deviceSpec}
                                                hideImage={true}
                                                typeData={deviceData}
                                                dataType="obj"
                                                haveModal={false}
                                            />
                                        </div>
                                    </div>
                                </TabPane>
                            </Tabs>
                        </Row>
                    )}
                </div>
            </div>
            <div style={{ textAlign: "right" }}>
                <Button
                    onClick={() => props.handleCancel()}
                    className="button-secondary"
                    style={{ width: "200px" }}
                    shape="round"
                >
                    {strings.aboutPage.modal.closeModalBtn}
                </Button>
            </div>
        </div>
    );
}
export default ModalContent;
