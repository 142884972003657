import { useState } from "react";
import { Slider, InputNumber } from "antd";
import "./CompareSlider.scss";

function FilterOptions(props) {
    const max_value = Math.max(...props.values.values.map((v) => v.value));
    const min_value = Math.min(...props.values.values.map((v) => v.value));
    let step = 10 ** (max_value.toFixed(0).toString().length - 2);

    const [leftVal, setLeftVal] = useState(min_value);
    const [rightVal, setRightVal] = useState(max_value);

    return (
        <div>
            <div className="compslider-input-container">
                <InputNumber
                    step={props.incrementStep ?? step}
                    min={min_value}
                    max={max_value}
                    defaultValue={min_value}
                    value={leftVal ?? min_value}
                    onChange={(val) => {
                        setLeftVal(val);
                        props.onAfterChange(leftVal, rightVal);
                    }}
                />
                <InputNumber
                    step={props.incrementStep ?? step}
                    min={min_value}
                    max={max_value}
                    defaultValue={max_value}
                    value={rightVal ?? max_value}
                    onChange={(val) => {
                        setRightVal(val);
                        props.onAfterChange(leftVal, val);
                    }}
                />
            </div>
            <Slider
                range
                tooltipVisible={false}
                step={props.incrementStep ?? step}
                min={min_value}
                max={max_value}
                defaultValue={[min_value, max_value]}
                value={[leftVal ?? min_value, rightVal ?? max_value]}
                onChange={([valMin, valMax]) => {
                    setLeftVal(valMin);
                    setRightVal(valMax);
                }}
                onAfterChange={([valMin, valMax]) => props.onAfterChange(valMin, valMax)}
            />
        </div>
    );
}
export default FilterOptions;
