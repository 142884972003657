import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import "antd/dist/antd.min.css"
import AppLayout from "./AppLayout.js"

function App() {
    return (
        <Router>
            <AppLayout/>
        </Router>
    );
}

export default App;
