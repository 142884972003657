import React from "react";
import { Row, Col, Spin } from "antd";
import strings from "../strings/strings";
import FacilityCard from "../components/FacilityCard.js";
import HtmlComponent from "../components/HtmlComponent.js";
import EU_image from "../assets/images/europeiska_unionen.png";
import getUniqueKeyValuesFromSubDevices from "../helpers/getUniqueKeyValuesFromSubDevices.js";
import MultiFacilityModal from "../components/FacilityModal/MultiFacilityModal";
import { IsTabletOrMobileComponent } from "../components/IsTabletOrMobile";

export default class About extends React.Component {
    state = {
        isModalVisible: false,
        visibleModalIndex: 0,
        isFullscreen: (() => {
            const params = new URLSearchParams(window.location.search).getAll("fullscreen");
            return params.length > 0 && params[0] !== "0";
        })(),
    };

    checkUrlParams = () => {
        const params = new URLSearchParams(window.location.search).getAll("device");
        if (params.length > 0) this.showModalOnURLParam(params[0]);
    };

    showModal = (index) => {
        this.setState({
            isModalVisible: true,
            visibleModalIndex: index,
        });

        const params = new URLSearchParams([["device", this.props.devices[index].identity]]);
        this.props.history.push({ search: params.toString() });
    };

    handleCancel = () => {
        this.props.history.push({ search: "" });
        this.setState({ isModalVisible: false });
    };

    changeURl = (identifier) => {
        const params = new URLSearchParams([["device", identifier]]);
        this.props.history.push({ search: params.toString() });
    };

    /** @param {string} identifier */
    showModalOnURLParam = (identifier) => {
        let visible = true;
        let index = -1;

        if (this.props.devices) {
            index = this.props.devices.findIndex((v) => v.identity === identifier);
        }

        if (visible !== this.state.isModalVisible) {
            this.setState({ isModalVisible: visible });
        }
        if (this.state.visibleModalIndex !== index) {
            this.setState({ visibleModalIndex: index });
        }
    };

    componentDidMount() {
        this.checkUrlParams();
    }

    componentDidUpdate(prevProps, _prevState) {
        if (prevProps.isLoading !== this.props.isLoading && this.props.devices) {
            this.checkUrlParams();
        }
    }

    render() {
        return (
            <IsTabletOrMobileComponent>
                {(size) => (
                    <>
                        <div style={{ margin: 0 }} className="about-div">
                        <div className="home-text">
                            <h1 className="h1 white-color">{strings.aboutPage.title}</h1>
                            <Row style={{ marginBottom: "4rem" }}>
                                <h2 className="h2 white-color">{strings.aboutPage.subTitle}</h2>
                                <HtmlComponent value={strings.aboutPage.bodyText.join(" ")} />
                            </Row>
                            </div>

                    <Row
                style={{
                    border: "1px solid #d3d3d3",
                    borderRadius: "var(--borderrad)",
                    overflow: "hidden",
                    backgroundColor: "white",
                    marginBottom: "4em",
                }}
            >
                <div style={{ padding: "20px" }}>
                    <HtmlComponent value={strings.startPage.streetMapsText}></HtmlComponent>
                </div>
                <iframe
                    src="https://maps.stuns.se/stories?energiportalen=true"
                    width="100%"
                    height="600"
                    frameBorder="0"
                    title="Stuns Streetmaps"
                ></iframe>
            </Row>
                            <div
                                className="bg-white"
                                style={{
                                    borderRadius: "var(--borderrad)",
                                    margin: "1em 0",
                                }}
                            >

                                <Row justify="center">
                                    <Col style={{ padding: "2em", boxSizing: "border-box" }}>
                                        <h2 className="h2 grey-color">{strings.aboutPage.secondSubTitle}</h2>
                                        <Row justify="start" gutter={16}>
                                            {this.props.isLoading || this.props.devices === undefined ? (
                                                <Spin style={{ padding: "5em", margin: "auto" }} size="large" />
                                            ) : (
                                                this.props.devices.map((d, i) => (
                                                    <FacilityCard
                                                        key={i}
                                                        index={i}
                                                        onClick={this.showModal}
                                                        deviceId={d.deviceId}
                                                        device={d}
                                                        image={
                                                            d.properties
                                                                .find((p) => p.key === "image urls")
                                                                ?.value?.split(";")[0] ?? "-"
                                                        }
                                                        city={
                                                            d.properties.find((p) => p.key === "city")
                                                                ? d.properties.find((p) => p.key === "city").value
                                                                : "-"
                                                        }
                                                        name={
                                                            d.properties.find((p) => p.key === "display name")
                                                                ? d.properties.find((p) => p.key === "display name")
                                                                      .value
                                                                : "-"
                                                        }
                                                        cellType={getUniqueKeyValuesFromSubDevices(
                                                            d.subDevices,
                                                            "solar cell type"
                                                        )}
                                                        roofType={getUniqueKeyValuesFromSubDevices(
                                                            d.subDevices,
                                                            "roof type"
                                                        )}
                                                    />
                                                ))
                                            )}
                                        </Row>
                                        <div style={{ paddingBottom: "3rem" }}>
                                            <h2 className="h2 grey-color mb-0" style={{ marginTop: "3rem" }}>
                                                {strings.aboutPage.thirdSubTitle}
                                            </h2>
                                            <img
                                                src={strings.aboutPage.financeImage1}
                                                alt=""
                                                style={{ paddingRight: "1rem" }}
                                            ></img>
                                            <img
                                                src={strings.aboutPage.financeImage2}
                                                alt=""
                                                style={{ paddingRight: "1rem" }}
                                            ></img>
                                        </div>
                                        <div>
                                            <h2 className="h2 grey-color mb-0">{strings.aboutPage.financeEU}</h2>
                                            <img src={EU_image} alt=""></img>
                                        </div>f7rvaribajwzs4vcw7cj6zkh6eqvvn5p3ywa7jsw5nrvtn2j6tjq
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <MultiFacilityModal
                            isFullscreen={this.state.isFullscreen}
                            isLoading={this.props.isLoading}
                            onChange={(identifier) => this.changeURl(identifier)}
                            size={size}
                            handleCancel={this.handleCancel}
                            devices={this.props.devices}
                            visible={this.state.isModalVisible}
                            index={this.state.visibleModalIndex}
                        />
                    </>
                )}
            </IsTabletOrMobileComponent>
        );
    }
}
