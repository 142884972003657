import axios from "axios";

const apiBaseUrl = "https://stunssolar.azurewebsites.net/api";

export function getDevices() {
    return axios.get(`${apiBaseUrl}/devices`);
}

export function getDeviceById(id) {
    return axios.get(`${apiBaseUrl}/devices/${id}`);
}

export function getMomentaneousValues() {
    return axios.get(`${apiBaseUrl}/momentaneous-data`);
}

export function getEfficiencyIndexData(deviceIds) {
    if (!deviceIds || deviceIds?.length < 1) return new Promise(() => ({}));
    const ids = `deviceIds=${deviceIds.join(",")}&`;
    return axios.get(
        `${apiBaseUrl}/efficiency-index-data?from=2020-04-01&to=2020-04-22&${ids}`
    );
}
