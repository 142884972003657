import React, { useEffect } from "react";
import { Row, Col } from "antd";
import strings from "../strings/strings";
import HtmlComponent from "../components/HtmlComponent.js";
import { Card } from "antd";
import BlobDownload from "../components/blob-download/BlobDownload";

function OpenData() {
    useEffect(() => {
        const entryScript = document.createElement("script");
        entryScript.src = "https://static.cdn.entryscape.com/embed/catalog/latest/embed.js";
        entryScript.async = true;
        entryScript.setAttribute("data-entry-id", "2");
        entryScript.setAttribute("data-context-id", "1");
        entryScript.setAttribute("data-theme-style", "IMG");
        entryScript.setAttribute("data-repository", "https://stuns.entryscape.net");
        entryScript.setAttribute("data-target", "_blank");
        document.getElementById("entryscape-container").appendChild(entryScript);
    }, []);

    return (
        <div style={{ marginBottom: "4em" }}>
                <div className="home-text">
            <h1 className="h1 white-color">{strings.openDataPage.title}</h1>
            <Row>
                <Col className="about-text-col" style={{ marginBottom: "4rem" }}>
                    <h1 className="h2 white-color">
                        <HtmlComponent value={strings.openDataPage.subTitle1} />
                    </h1>
                    <HtmlComponent value={strings.openDataPage.bodyText1.join(" ")} />
                    <h2 className="h2 white-color" style={{ marginTop: "4em" }}>
                        <HtmlComponent value={strings.openDataPage.subTitle2} />
                    </h2>
                    <HtmlComponent value={strings.openDataPage.bodyText2.join(" ")} />
                </Col>
            </Row>
            </div>
            <Card style={{ borderRadius: "var(--borderrad)" }}>
                <BlobDownload />
            </Card>
            <Card
                id="entryscape-container"
                style={{ marginTop: "1rem", borderRadius: "var(--borderrad)", overflow: "hidden" }}
            >
                <HtmlComponent value={strings.openDataPage.openDataParagraph.join(" ")} />
            </Card>
        </div>
    );
}
export default OpenData;
