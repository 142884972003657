import React, { useState } from "react";
import { Table } from "antd";
import FacilityModal from "../FacilityModal/FacilityModal";

import "./SpecificationTable.scss";

function SpecificationTable(props) {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [modalDeviceID, setModalDeviceID] = useState(undefined);

    const showModal = (id) => {
        setModalDeviceID(id);
        setIsModalVisible(!isModalVisible);
    };

    const closeModal = () => {
        setIsModalVisible(false);
    };

    const columns = [
        {
            title: props.title,
            dataIndex: "data",
            key: "data",
        },
    ];

    if (props.dataType !== "obj")
        return (
            <Table
                dataSource={props.data}
                columns={columns}
                pagination={false}
                className={props.isDataTable ? "table-data" : "table-columns"}
            />
        );

    return props.data.length < 1 ? (
        <span style={{ textAlign: "center", display: "block" }}>{props.empty}</span>
    ) : (
        <>
            <table>
                <tbody>
                    {!props.hideImage && (
                        <tr style={{ borderBottom: "1px solid rgba(0, 0, 0, 0.2)", borderBottomStyle: "solid" }}>
                            <th></th>
                            {props.typeData.map((v) => (
                                <th className="spectable-image-header" key={"spectable" + v.name}>
                                    <div
                                        className="spectable-header-div"
                                        style={{ cursor: props.haveModal ? "pointer" : "default" }}
                                        onClick={() => {
                                            if (v.onClick === "showModal") {
                                                showModal(v.device.deviceId);
                                            } else {
                                                if (isModalVisible) closeModal();
                                            }
                                        }}
                                    >
                                        {v.image === undefined || v.image === "-" ? (
                                            <div className="spectable-image">
                                                <span>Bild kommer snart</span>
                                            </div>
                                        ) : (
                                            <img className="spectable-image" src={v.image} alt="empty..." />
                                        )}
                                        <span>{v.name}</span>
                                    </div>
                                </th>
                            ))}
                        </tr>
                    )}
                    {props.data.map((v) => (
                        <React.Fragment key={v.key}>
                            <tr>
                                <th className="spectable-header" colSpan="100%">
                                    {v.key}
                                </th>
                            </tr>
                            {v.value.map((obj) => (
                                <tr key={obj.key} className="spectable-prop-row">
                                    <td className="spectable-prop-header">{obj.key}</td>
                                    {(obj?.value ?? []).map((value, i) => {
                                        const moreInfo = obj.key === "Mer information";
                                        return (
                                            <td key={obj.key + i} className="spectable-prop">
                                                <span
                                                    style={{
                                                        cursor: moreInfo ? "pointer" : "default",
                                                        textDecoration: moreInfo ? "underline" : "none",
                                                    }}
                                                    onClick={() => {
                                                        if (moreInfo) {
                                                            showModal(obj.ids[i]);
                                                        } else {
                                                            if (isModalVisible) closeModal();
                                                        }
                                                    }}
                                                >
                                                    {value}
                                                </span>
                                            </td>
                                        );
                                    })}
                                </tr>
                            ))}
                        </React.Fragment>
                    ))}
                </tbody>
            </table>
            {props.haveModal && (
                <FacilityModal
                    key={isModalVisible + modalDeviceID}
                    visible={isModalVisible}
                    id={modalDeviceID}
                    handleCancel={closeModal}
                    device={(props.allData ?? []).find((v) => v.deviceId === modalDeviceID) ?? undefined}
                />
            )}
        </>
    );
}
export default SpecificationTable;
