import React, { useRef } from "react";
import useIntersectionObserver from "@react-hook/intersection-observer";

/**
 * @param {Object} props
 * @param {React.ReactElement} props.children
 * @param {React.CSSProperties} props.style
 */
function LazyComponent(props) {
    const containerRef = useRef();
    const lockRef = useRef(false);
    const { isIntersecting } = useIntersectionObserver(containerRef);

    if (isIntersecting) lockRef.current = true;
    return (
        <div ref={containerRef} style={props.style}>
            {lockRef.current && props.children}
        </div>
    );
}
export default LazyComponent;
