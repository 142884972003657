import React, { useState, useEffect } from "react";

function IsTabletOrMobile() {
    const [windowSize, setWindowSize] = useState({
        width: undefined,
        height: undefined,
    });

    useEffect(() => {
        function handleResize() {
            setWindowSize({
                width: window.innerWidth,
                height: window.innerHeight,
            });
        }

        window.addEventListener("resize", handleResize);

        handleResize();

        return () => window.removeEventListener("resize", handleResize);
    }, []);

    return { mobile: windowSize.width < 700, tablet: windowSize.width < 1024 };
}
export default IsTabletOrMobile;

export class IsTabletOrMobileComponent extends React.Component {
    state = {
        size: this.handleResize(true),
    };

    handleResize(dryRun = false) {
        const value = { isMobile: window.innerWidth < 700, isTablet: window.innerWidth < 1024 };
        if (dryRun) return value;
        if (value.isMobile !== this.state.size.isMobile || value.isTablet !== this.state.size.isTablet) {
            this.setState({ size: value });
        }
    }

    resizeEvent = () => {
        this.handleResize();
    };

    componentDidMount() {
        window.addEventListener("resize", this.resizeEvent);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", () => this.resizeEvent);
    }

    render() {
        return this.props.children(this.state.size);
    }
}
