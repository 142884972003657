import React from "react";
import { Modal } from "antd";
import ModalContent from "./ModalContent";

function FacilityModal(props) {
    return (
        <Modal
            cantered
            width={1200}
            visible={props.visible}
            title={null}
            footer={null}
            onCancel={() => props.handleCancel()}
        >
            <ModalContent
                device={props.device}
                visible={props.visible}
                handleCancel={props.handleCancel}
                id={props.id}
            />
        </Modal>
    );
}

export default FacilityModal;
